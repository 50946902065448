import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DataService {
    constructor(private http: HttpClient) {
       
    }

    getService(url: string): Observable<any> {
        return this.http
            .get(url)
    }

    getServiceWithDynamicQueryTerm(url: string, key: string, val: string): Observable<any> {
        return this.http
            .get(url + "/?" + key + "=" + val)
    }

    getServiceWithMultipleQueryTerm(url: string, query: string): Observable<any> {
        return this.http
            .get(url + "?" + query)
    }

    getServiceWithComplexObjectAsQueryString(url: string, param: any): Observable<any> {
        let params: any = new URLSearchParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params.set(key, val);
            }
        }
        return this.http
            .get(url, params)
    }

    createServiceFullResponse(url: string, param: any): Observable<any> {
        return this.http
            .post(url, param, {
                observe: 'response'
            })
    }

    createService(url: string, param: any): Observable<any> {
        return this.http
            .post(url, param)
    }

    
     updateService(url: string, param: any): Observable<any> {
        return this.http
            .put(url, param)
    }

    patchService(url: string, param: any): Observable<any> {
        return this.http
            .patch(url, param)
    }

    deleteService(url: string, param: any): Observable<any> {
        let params: any = new URLSearchParams();
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                let val = param[key];
                params.set(key, val);
            }
        }
        return this.http
            .delete(url, params)
    }

    deleteServiceWithId(url: string, key: string, val: string): Observable<any> {
        return this.http
            .delete(url + "/?" + key + "=" + val)
    }

    private extractData(res: any) {
        let body = res;
        return body || {};
    }

    private handleError(error: any) {
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg);
        return Observable.throw(errMsg);
    }

    getRequest(url: string): Promise<any> {
        return new Promise<any>(
            function (resolve, reject) {
                const request = new XMLHttpRequest();
                request.onload = function () {
                    if (request.status === 200) {
                        resolve(request.response);
                    } else {
                        reject(new Error(request.statusText));
                    }
                };
                request.onerror = function () {
                    reject(new Error('XMLHttpRequest Error: ' + request.statusText));
                };
                request.open('GET', url);
                request.send();
            });
    }
}