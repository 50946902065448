import { Component, OnInit } from '@angular/core';
import { ShareService } from './common/share.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Haryana Panchal Brahmin Mahasabha';

  constructor(public share: ShareService) {}

  ngOnInit(): void {
    this.getLang()
  }

  getLang() {
    let lang = localStorage.getItem('hpbm-lang');
    if (lang) {
      this.share.home.language = lang;
    }
  }
}