<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;विश्वकर्मा&nbsp;<i class="fas fa-angle-right"></i>&nbsp;महाप्रभु
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">भगवान श्री विश्वकर्मा - महाप्रभु</h1>
    <p>सर्वव्यापक परम ब्रह्म एक है। वह सर्वथा पूर्ण है। वह सत्य, सर्वगत और सर्वज्ञ है। अज, अनादि, अनावृत्त और अविनाशी है। उस परब्रह्म में संपूर्ण सृष्टि समाहित है। उस परमेश्वर ने प्रकृति से लेकर घास पर्यन्त जगत को रचा है। ये सब पदार्थ ईश्वर के द्वारा रचे
        होने के कारण उसका नाम महादेव, महान शिल्पी, शिल्पाचर्य और विश्वकर्मा है। </p>
    <p class="shlok">विश्वकर्मा विमना आदिव्हाया धाता विधाता परमोत सन्दृक्।<br/> तेषामिष्टानि समिषा मदन्ति यत्रा सप्तऋषिन्पर एक माहुः।।</p>
    <p class="shlokSource">ऋग्वेद मण्डल 10 सूक्त 82 मन्त्र 2</p>
    <p>सृष्टि को रचने वाला विशेष ज्ञान वाला आकाश के समान सर्वत्र व्यापक सब विश्व को धारण करने वाला विश्व के विधान को रचने वाला परम ज्ञानी सब से सूक्ष्म तत्त्व और सम्यक रूप से सब का द्रष्टा जिसके विषय में ज्ञानीजन कहते हैं कि वह सात ऋषिओं, मन और बुद्धि से
        परे है, इनसे ज्ञेय नहीं है। जिसे एक अद्वितीय कहते हैं। जिसके आश्रय में उन सात ऋषियों के प्रियभोग इसकी ईक्ष्ण शक्ति से सुखदायक होते हैं। </p>
    <div class="inMatterImg">
        <img src="assets/images/HPBM-Shree-Vishwakarma-Ek-Dev.jpeg" />
    </div>
    <p>भावार्थ:- वह विश्वकर्मा ईश्वर इन्द्रियातीत है, सर्वशक्तिमान है। आत्मा भी शरीर में इन्द्रियों से सूक्ष्म है। ईश्वर एक अद्वितीय तत्त्व है। </p>
    <p>निःसन्देह विश्वकर्मा महाप्रभु हैं। सच पूछिए तो भगवान् विश्वकर्मा सही अर्थ में निर्माण के देवता और शिल्प विज्ञान के आदि प्रवर्तक हैं, जिन्होंने श्रम और नियम का प्रवर्त्तन कर जगत का मनोहर और नयनाभिराम रूप बनाया है। ये ही सर्वप्रथम श्रमिक नियामक हैं
        जिन्होंने पञ्च तत्त्वों की रचना कर सम्यक सृष्टि का निर्माण नियमन किया है। </p>
    <p>वेदों में विशवतः चक्षुरुत विश्वतोमुखो विश्वतोबाहुरुत विश्वस्पात कहकर इनकी सर्वव्यापकता, सर्वज्ञता, शक्ति-सम्पन्ता और अनन्तता दर्शायी गयी है।</p>
</div>
<div class="container" *ngIf="share.home.language == 'English'">
    <h1 class="pageTitle">Shree Vishwakarma - Ek Dev</h1>
</div>
<app-footer></app-footer>