import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ShareService } from '../common/share.service';
import { DataService } from '../common/data.service';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css']
})
export class AddMemberComponent implements OnInit {

  MembershipForm: FormGroup;

  imageChangedEventLive: any = '';
  croppedImageLive: any = '';

  imageChangedEventAF: any = '';
  croppedImageAF: any = '';

  imageChangedEventAB: any = '';
  croppedImageAB: any = '';

  livePhoto: any = "";
  aadharFront: any = "";
  aadharBack: any = "";
  showImgCropper = [false, false, false];

  submitted: boolean = false;

  aadharExists: number = 0;

  constructor(public share: ShareService, private fb: FormBuilder, private ds: DataService, private router: Router,
    private spinner: NgxSpinnerService, private modalService: NgbModal, private http: HttpClient) { }

  ngOnInit(): void {
    this.MembershipForm = this.fb.group({
      salutation: ['', Validators.required],
      name: ['', Validators.required],
      surname: [''],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      gotra: [''],
      maritalStatus: ['', Validators.required],
      childrenCount: [0, Validators.required],
      aadhar: ['', Validators.required],
      mobile: ['', [Validators.required]],
      email: [''],
      address: [''],
      city: ['', Validators.required],
      district: [''],
      state: ['', Validators.required],
      pin: [''],
      qualificationLevel: [''],
      qualification: [''],
      occupation: [''],
      company: [''],
      motherName: ['', Validators.required],
      motherGotra: [''],
      fatherName: ['', Validators.required],
      fatherGotra: [''],
      spouseName: [''],
      spouseGotra: [''],
      children: this.fb.array([])
    })
  }

  childrenArray(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      surname: [''],
      gender: ['', Validators.required],
      dob: [''],
      mobile: [''],
      email: [''],
      qualification: [''],
      occupation: [''],
      maritalStatus: [''],
      children: [null, Validators.max(9)]
    })
  }

  addChildren(): void {
    (<FormArray>this.MembershipForm.get('children')).push(this.childrenArray())
  }

  removeChildren(i: number): void {
    (<FormArray>this.MembershipForm.get('children')).removeAt(i)
  }

  log() {
    let formData = JSON.stringify(this.MembershipForm.get('children').value)
    console.log(this.MembershipForm.controls.children.value)
  }

  fileChangeEventLive(event: any): void {
    this.imageChangedEventLive = event;
    this.showImgCropper[0] = true;
  }

  imageCroppedLive(event: ImageCroppedEvent) {
    this.croppedImageLive = event.base64;
  }

  fileChangeEventAF(event: any): void {
    this.imageChangedEventAF = event;
    this.showImgCropper[1] = true;
  }

  fileChangeEventAB(event: any): void {
    this.imageChangedEventAB = event;
    this.showImgCropper[2] = true;
  }

  imageCroppedAF(event: ImageCroppedEvent) {
    this.croppedImageAF = event.base64;
  }

  imageCroppedAB(event: ImageCroppedEvent) {
    this.croppedImageAB = event.base64;
  }

  imageLoaded() {
  }

  cropperReady() {
    // cropper ready

  }
  loadImageFailed() {
    // show message
  }

  imageLoadedLive(event: any) {
  }

  cropperReadyLive() {
    // cropper ready

  }
  loadImageFailedLive() {
    // show message
  }

  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI.replace(/^data:image\/(png|jpeg);base64,/, ""));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  convert(event: any) {
    var file = event.target.files[0];
    this.getBase64(file).then(
      data => console.log(data)
    );
  }

  checkAadhar() {
    if (this.MembershipForm.controls.aadhar.value && this.MembershipForm.controls.aadhar.value.length == 12) {
      this.spinner.show();
      this.ds.createService(environment.apiURL + "GenericSqlDB/PostExceProcTable",
        {
          ProcedureName: 'CheckAadhar', SqlParamsArr: [
            { ParamName: '@Referrer', ParamValue: window.location.origin },
            { ParamName: '@AadharNo', ParamValue: this.MembershipForm.controls.aadhar.value },
          ]
        }).subscribe
        (data => {
          if (data && data.length > 0) {
            if (data[0].Status == 'Exists') {
              this.aadharExists = 2;
            }
            else if (data[0].Status == 'Not Exists') {
              this.aadharExists = 1;
            }
          }
          else {
          }
          this.spinner.hide();
        })
    }
  }

  uploadPhoto() {
    if (this.MembershipForm.touched && this.MembershipForm.valid && this.aadharExists == 1) {
      this.spinner.show();
      let a = this.dataURItoBlob(this.croppedImageAF);
      let b = this.dataURItoBlob(this.croppedImageAB);
      let c = this.dataURItoBlob(this.croppedImageLive);
      let formData = new FormData();
      if (this.showImgCropper[0]) {
        let image2 = new File([c], "Member-", { type: 'image/jpeg' });
        formData.append('image', image2, image2.name);
      }
      if (this.showImgCropper[1]) {
        let image = new File([a], "AadharCard-Front-", { type: 'image/jpeg' });
        formData.append('image', image, image.name);
      }
      if (this.showImgCropper[2]) {
        let image1 = new File([b], "AadharCard-Back-", { type: 'image/jpeg' });
        formData.append('image', image1, image1.name);
      }
      this.ds.createService(environment.apiURL + 'GenericSqlDB/UploadMemberImages', formData)
        .subscribe(res2 => {
          this.livePhoto = res2.filter((item: any) => item.toLowerCase().indexOf('member') > -1)[0] || "";
          this.aadharFront = res2.filter((item: any) => item.toLowerCase().indexOf('aadharcard-front') > -1)[0] || "";
          this.aadharBack = res2.filter((item: any) => item.toLowerCase().indexOf('aadharcard-back') > -1)[0] || "";
          this.submitForm();
        })
    }
    else {
      window.alert("कृपया फ़ॉर्म सही से भरें।")
    }
  }

  submitForm() {
    this.ds.createService(environment.apiURL + "GenericSqlDB/PostExceProcNonQuery",
      {
        ProcedureName: 'InsertMembers', SqlParamsArr: [
          { ParamName: '@Referrer', ParamValue: window.location.origin },
          { ParamName: '@Salutation', ParamValue: this.MembershipForm.controls.salutation.value },
          { ParamName: '@Name', ParamValue: this.MembershipForm.controls.name.value },
          { ParamName: '@SurName', ParamValue: this.MembershipForm.controls.surname.value },
          { ParamName: '@Gender', ParamValue: this.MembershipForm.controls.gender.value },
          { ParamName: '@DOB', ParamValue: this.MembershipForm.controls.dob.value },
          { ParamName: '@Gotra', ParamValue: this.MembershipForm.controls.gotra.value },
          { ParamName: '@MaritalStatus', ParamValue: this.MembershipForm.controls.maritalStatus.value },
          { ParamName: '@ChildrenCount', ParamValue: this.MembershipForm.controls.childrenCount.value },
          { ParamName: '@AadharNo', ParamValue: this.MembershipForm.controls.aadhar.value },
          { ParamName: '@MobileNo', ParamValue: this.MembershipForm.controls.mobile.value },
          { ParamName: '@EmailID', ParamValue: this.MembershipForm.controls.email.value },
          { ParamName: '@Address', ParamValue: this.MembershipForm.controls.address.value },
          { ParamName: '@City', ParamValue: this.MembershipForm.controls.city.value },
          { ParamName: '@District', ParamValue: this.MembershipForm.controls.district.value },
          { ParamName: '@State', ParamValue: this.MembershipForm.controls.state.value },
          { ParamName: '@PIN', ParamValue: this.MembershipForm.controls.pin.value },
          { ParamName: '@QualificationLevel', ParamValue: this.MembershipForm.controls.qualificationLevel.value },
          { ParamName: '@Qualification', ParamValue: this.MembershipForm.controls.qualification.value },
          { ParamName: '@Occupation', ParamValue: this.MembershipForm.controls.occupation.value },
          { ParamName: '@Company', ParamValue: this.MembershipForm.controls.company.value },
          { ParamName: '@MotherName', ParamValue: this.MembershipForm.controls.motherName.value },
          { ParamName: '@MotherGotra', ParamValue: this.MembershipForm.controls.motherGotra.value },
          { ParamName: '@FatherName', ParamValue: this.MembershipForm.controls.fatherName.value },
          { ParamName: '@FatherGotra', ParamValue: this.MembershipForm.controls.fatherGotra.value },
          { ParamName: '@SpouseName', ParamValue: this.MembershipForm.controls.spouseName.value },
          { ParamName: '@SpouseGotra', ParamValue: this.MembershipForm.controls.spouseGotra.value },
          { ParamName: '@MemberPhoto', ParamValue: this.livePhoto },
          { ParamName: '@AadharCardFront', ParamValue: this.aadharFront },
          { ParamName: '@AadharCardBack', ParamValue: this.aadharBack },
          { ParamName: '@Children', ParamValue: JSON.stringify(this.MembershipForm.get('children').value) }
        ]
      }).subscribe
      (data => {
        if (data && data > 0) {
          this.notifyTelegram();
          if (this.MembershipForm.controls.email.value && this.MembershipForm.controls.email.value.length > 0) {
            this.sendMail();
          }
          window.alert("धन्यवाद! हमें आपकी जानकारी प्राप्त हो गयी हैं। हमारी टीम आपको सम्पर्क करेगी।");
          setTimeout(() => {
            this.router.navigate(['']).then(() => {
              this.router.navigate(['/new-member-registration'])
            })
          }, 500);

        }
        else {
        }
        this.spinner.hide()
      })
  }

  // submitForm() {
  //   if (this.MembershipForm.touched && this.MembershipForm.valid && this.aadharExists == 1) {
  //     this.spinner.show()
  //     if (this.showImgCropper[0]) {
  //       let c = this.dataURItoBlob(this.croppedImageLive);
  //       let image2 = new File([c], "Member-", { type: 'image/jpeg' });
  //       let formData2 = new FormData();
  //       formData2.append('image', image2, image2.name);
  //       this.http.post(environment.apiURL + 'GenericSqlDB/UploadMemberImages', formData2)
  //         .subscribe(res2 => {
  //           this.livePhoto = res2
  //           this.ds.createService(environment.apiURL + "GenericSqlDB/PostExceProcNonQuery",
  //             {
  //               ProcedureName: 'InsertMembers', SqlParamsArr: [
  //                 { ParamName: '@Referrer', ParamValue: window.location.origin },
  //                 { ParamName: '@Salutation', ParamValue: this.MembershipForm.controls.salutation.value },
  //                 { ParamName: '@Name', ParamValue: this.MembershipForm.controls.name.value },
  //                 { ParamName: '@SurName', ParamValue: this.MembershipForm.controls.surname.value },
  //                 { ParamName: '@Gender', ParamValue: this.MembershipForm.controls.gender.value },
  //                 { ParamName: '@DOB', ParamValue: this.MembershipForm.controls.dob.value },
  //                 { ParamName: '@Gotra', ParamValue: this.MembershipForm.controls.gotra.value },
  //                 { ParamName: '@MaritalStatus', ParamValue: this.MembershipForm.controls.maritalStatus.value },
  //                 { ParamName: '@ChildrenCount', ParamValue: this.MembershipForm.controls.childrenCount.value },
  //                 { ParamName: '@AadharNo', ParamValue: this.MembershipForm.controls.aadhar.value },
  //                 { ParamName: '@MobileNo', ParamValue: this.MembershipForm.controls.mobile.value },
  //                 { ParamName: '@EmailID', ParamValue: this.MembershipForm.controls.email.value },
  //                 { ParamName: '@Address', ParamValue: this.MembershipForm.controls.address.value },
  //                 { ParamName: '@City', ParamValue: this.MembershipForm.controls.city.value },
  //                 { ParamName: '@District', ParamValue: this.MembershipForm.controls.district.value },
  //                 { ParamName: '@State', ParamValue: this.MembershipForm.controls.state.value },
  //                 { ParamName: '@PIN', ParamValue: this.MembershipForm.controls.pin.value },
  //                 { ParamName: '@QualificationLevel', ParamValue: this.MembershipForm.controls.qualificationLevel.value },
  //                 { ParamName: '@Qualification', ParamValue: this.MembershipForm.controls.qualification.value },
  //                 { ParamName: '@Occupation', ParamValue: this.MembershipForm.controls.occupation.value },
  //                 { ParamName: '@Company', ParamValue: this.MembershipForm.controls.company.value },
  //                 { ParamName: '@MotherName', ParamValue: this.MembershipForm.controls.motherName.value },
  //                 { ParamName: '@MotherGotra', ParamValue: this.MembershipForm.controls.motherGotra.value },
  //                 { ParamName: '@FatherName', ParamValue: this.MembershipForm.controls.fatherName.value },
  //                 { ParamName: '@FatherGotra', ParamValue: this.MembershipForm.controls.fatherGotra.value },
  //                 { ParamName: '@SpouseName', ParamValue: this.MembershipForm.controls.spouseName.value },
  //                 { ParamName: '@SpouseGotra', ParamValue: this.MembershipForm.controls.spouseGotra.value },
  //                 { ParamName: '@MemberPhoto', ParamValue: res2 },
  //                 { ParamName: '@AadharCardFront', ParamValue: this.aadharFront },
  //                 { ParamName: '@AadharCardBack', ParamValue: this.aadharBack },
  //                 { ParamName: '@Children', ParamValue: JSON.stringify(this.MembershipForm.get('children').value) }
  //               ]
  //             }).subscribe
  //             (data => {
  //               if (data && data > 0) {
  //                 this.notifyTelegram();
  //                 if (this.MembershipForm.controls.email.value && this.MembershipForm.controls.email.value.length > 0) {
  //                   this.sendMail();
  //                 }
  //                 window.alert("धन्यवाद! हमें आपकी जानकारी प्राप्त हो गयी हैं। हमारी टीम आपको सम्पर्क करेगी।");
  //                 setTimeout(() => {
  //                   this.router.navigate(['']).then(() => {
  //                     this.router.navigate(['/new-member-registration'])
  //                   })
  //                 }, 500);

  //               }
  //               else {
  //               }
  //               this.spinner.hide()
  //             })
  //         });
  //     }
  //     else if (!this.showImgCropper[0]) {
  //       this.ds.createService(environment.apiURL + "GenericSqlDB/PostExceProcNonQuery",
  //         {
  //           ProcedureName: 'InsertMembers', SqlParamsArr: [
  //             { ParamName: '@Referrer', ParamValue: window.location.origin },
  //             { ParamName: '@Salutation', ParamValue: this.MembershipForm.controls.salutation.value },
  //             { ParamName: '@Name', ParamValue: this.MembershipForm.controls.name.value },
  //             { ParamName: '@SurName', ParamValue: this.MembershipForm.controls.surname.value },
  //             { ParamName: '@Gender', ParamValue: this.MembershipForm.controls.gender.value },
  //             { ParamName: '@DOB', ParamValue: this.MembershipForm.controls.dob.value },
  //             { ParamName: '@Gotra', ParamValue: this.MembershipForm.controls.gotra.value },
  //             { ParamName: '@MaritalStatus', ParamValue: this.MembershipForm.controls.maritalStatus.value },
  //             { ParamName: '@ChildrenCount', ParamValue: this.MembershipForm.controls.childrenCount.value },
  //             { ParamName: '@AadharNo', ParamValue: this.MembershipForm.controls.aadhar.value },
  //             { ParamName: '@MobileNo', ParamValue: this.MembershipForm.controls.mobile.value },
  //             { ParamName: '@EmailID', ParamValue: this.MembershipForm.controls.email.value },
  //             { ParamName: '@Address', ParamValue: this.MembershipForm.controls.address.value },
  //             { ParamName: '@City', ParamValue: this.MembershipForm.controls.city.value },
  //             { ParamName: '@District', ParamValue: this.MembershipForm.controls.district.value },
  //             { ParamName: '@State', ParamValue: this.MembershipForm.controls.state.value },
  //             { ParamName: '@PIN', ParamValue: this.MembershipForm.controls.pin.value },
  //             { ParamName: '@QualificationLevel', ParamValue: this.MembershipForm.controls.qualificationLevel.value },
  //             { ParamName: '@Qualification', ParamValue: this.MembershipForm.controls.qualification.value },
  //             { ParamName: '@Occupation', ParamValue: this.MembershipForm.controls.occupation.value },
  //             { ParamName: '@Company', ParamValue: this.MembershipForm.controls.company.value },
  //             { ParamName: '@MotherName', ParamValue: this.MembershipForm.controls.motherName.value },
  //             { ParamName: '@MotherGotra', ParamValue: this.MembershipForm.controls.motherGotra.value },
  //             { ParamName: '@FatherName', ParamValue: this.MembershipForm.controls.fatherName.value },
  //             { ParamName: '@FatherGotra', ParamValue: this.MembershipForm.controls.fatherGotra.value },
  //             { ParamName: '@SpouseName', ParamValue: this.MembershipForm.controls.spouseName.value },
  //             { ParamName: '@SpouseGotra', ParamValue: this.MembershipForm.controls.spouseGotra.value },
  //             { ParamName: '@MemberPhoto', ParamValue: '' },
  //             { ParamName: '@AadharCardFront', ParamValue: this.aadharFront },
  //             { ParamName: '@AadharCardBack', ParamValue: this.aadharBack },
  //             { ParamName: '@Children', ParamValue: JSON.stringify(this.MembershipForm.get('children').value) }
  //           ]
  //         }).subscribe
  //         (data => {
  //           if (data && data > 0) {
  //             this.notifyTelegram();
  //             if (this.MembershipForm.controls.email.value && this.MembershipForm.controls.email.value.length > 0) {
  //               this.sendMail();
  //             }
  //             window.alert("धन्यवाद! हमें आपकी जानकारी प्राप्त हो गयी हैं। हमारी टीम आपको सम्पर्क करेगी।");
  //             setTimeout(() => {
  //               this.router.navigate(['']).then(() => {
  //                 this.router.navigate(['/new-member-registration'])
  //               })
  //             }, 500);

  //           }
  //           else {
  //           }
  //           this.spinner.hide()
  //         })
  //     }
  //   }
  //   else {
  //     window.alert("कृपया फ़ॉर्म सही से भरें।")
  //   }
  // }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  sendMail() {
    this.spinner.show();
    let subject: string = "Thank you for Registering with Us"
    let mail = {
      "owner_id": "89549865",
      "token": "EvbdReEqM5l6CzVPPgIC",
      "smtp_user_name": "smtp89286320",
      "message": {
        "template_id": "df9ef481d5a99d6fb474a058d85e614b",
        "subject": subject,
        "from_email": "hello@panchalbrahminmahasabha.com",
        "from_name": "Haryana Panchal Brahmin Mahasabha",
        "to": [
          {
            "email": this.MembershipForm.controls.email.value,
            "name": this.MembershipForm.controls.name.value,
            "type": "to"
          }
        ],
        "headers": {
          "Reply-To": "hello@panchalbrahminmahasabha.com",
          "X-Unique-Id": "id",
        },
        "dynamic_value": {
        },
      }
    }
    this.http.post(environment.mailURL + "messages/sendTemplate", mail).subscribe
      (data => {
        if (data) {
        }
        else {
        }
        this.spinner.hide()
      });
  }

  notifyTelegram() {
    let url = "https://api.telegram.org/bot5688870181:AAFmuM1VRAnN0UnTerVzTigv7fy4v4IwHA0/sendMessage?chat_id=-1001876324001&text="
    let from = this.MembershipForm.controls.name.value;
    if (this.MembershipForm.controls.city.value && this.MembershipForm.controls.city.value.length > 0) {
      from = this.MembershipForm.controls.name.value + " from " + this.MembershipForm.controls.city.value
    }
    let message = "Dear Team%0a%0a" + from + " has REGISTERED with us.%0a%0aTheir details:%0a%0aName: " + this.MembershipForm.controls.name.value + "%0a%0aMobile: " + this.MembershipForm.controls.mobile.value + "%0a%0aCheck Admin Panel for Details.%0a%0aThanks!%0aTeam HPBM"
    this.http.get(url + message).subscribe
      (data => {
        if (data) {
        }
        else {
        }
        this.spinner.hide()
      });;
  }
}
