import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ShareService } from '../common/share.service';
import { DataService } from '../common/data.service';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner'
import { NgbModal, NgbModalRef, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  slug: string;
  gallery = [];
  modal: NgbModalRef;

  constructor(
    public share: ShareService,
    private ds: DataService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.aRoute.params.subscribe(params => {
      this.slug = (params["id"]);
      this.getGallery();
    });
  }

  getGallery() {
    this.spinner.show();
    this.ds.createService(environment.apiURL + "GenericSqlDB/ExecuteProcedureMultiPostJSON",
      {
        ProcedureName: 'GetGallery', SqlParamsArr: [
          { ParamName: '@UserID', ParamValue: 0 },
          { ParamName: '@Slug', ParamValue: this.slug }
        ]
      }).subscribe
      (data => {
        if (data && data.length > 0) {
          this.gallery = data;
        }
        else {
        }
        this.spinner.hide()
      });
  }

  openmodal(modal) {
    this.modal = this.modalService.open(modal, { keyboard: false, centered: true, size: 'lg' });
  }
}