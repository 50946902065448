import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlbumsComponent } from './albums/albums.component';
import { Albums2016Component } from './albums2016/albums2016.component';
import { Albums2018Component } from './albums2018/albums2018.component';
import { EkDevComponent } from './vishwakarma/ek-dev/ek-dev.component';
import { EkRishiComponent } from './vishwakarma/ek-rishi/ek-rishi.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HomeComponent } from './home/home.component';
import { KaiSwaroopComponent } from './vishwakarma/kai-swaroop/kai-swaroop.component';
import { ShabdParichayComponent } from './vishwakarma/shabd-parichay/shabd-parichay.component';
import { VanshawaliComponent } from './vishwakarma/vanshawali/vanshawali.component';
import { YantroKeDevComponent } from './vishwakarma/yantro-ke-dev/yantro-ke-dev.component';
import { JanmDiwasComponent } from './vishwakarma/janm-diwas/janm-diwas.component';
import { ExecutiveMembersComponent } from './members/executive-members/executive-members.component';
import { EkUpadhiComponent } from './vishwakarma/ek-upadhi/ek-upadhi.component';
import { BusinessDetailComponent } from './business-detail/business-detail.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { EkJhalakComponent } from './mahasabha/ek-jhalak/ek-jhalak.component';
import { MahasabhaEventsComponent } from './mahasabha/mahasabha-events/mahasabha-events.component';
import { ContactComponent } from './contact/contact.component';
import { ManagementCommitteeComponent } from './members/management-committee/management-committee.component';
import { AddMemberComponent } from './add-member/add-member.component';
import { AddMemberLatestComponent } from './add-member-latest/add-member-latest.component';
import { MembersComponent } from './members/members/members.component';
import { DownloadMemberIdComponent } from './download-member-id/download-member-id.component';
import { AreaPrabhariComponent } from './members/area-prabhari/area-prabhari.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'vishwakarma-shabd-parichay', component: ShabdParichayComponent},
  {path: 'vishwakarma-mahaprabhu', component: EkDevComponent},
  {path: 'vishwakarma-ek-rishi', component: EkRishiComponent},
  {path: 'vishwakarma-kai-swaroop', component: KaiSwaroopComponent},
  {path: 'vishwakarma-vanshawali', component: VanshawaliComponent},
  {path: 'vishwakarma-ek-upadhi', component: EkUpadhiComponent},
  {path: 'vishwakarma-yantro-ke-dev', component: YantroKeDevComponent},
  {path: 'vishwakarma-janm-diwas', component: JanmDiwasComponent},
  {path: 'about-haryana-panchal-brahmin-mahasabha', component: EkJhalakComponent},
  {path: 'events-by-haryana-panchal-brahmin-mahasabha', component: MahasabhaEventsComponent},
  {path: 'list-of-hpbm-management-committee', component: ManagementCommitteeComponent},
  {path: 'list-of-hpbm-executive-members', component: ExecutiveMembersComponent},
  {path: 'list-of-hpbm-area-prabhari', component: AreaPrabhariComponent},
  {path: 'list-of-hpbm-members', component: MembersComponent},
  {path: 'contact-haryana-panchal-brahmin-mahasabha', component: ContactComponent},
  {path: 'gallery', component: AlbumsComponent},
  {path: 'gallery/:id', component: GalleryComponent},
  {path: 'gallery-2016', component: Albums2016Component},
  {path: 'gallery-2018', component: Albums2018Component},
  {path: 'not-found', component: NotFoundComponent},
  {path: 'business/:slug', component: BusinessDetailComponent},
  {path: 'new-member-registration', component: AddMemberComponent},
  {path: 'new-member-registration-2022', component: AddMemberLatestComponent},
  {path: 'download-member-id', component: DownloadMemberIdComponent},
  {path: '**', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
