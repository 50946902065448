<footer id="footer">
    <div class="footer-top">
        <div class="container">
            <div class="row sidebar">
                <div class="col-12 col-md-12 col-lg-12 widget">
                    <div class="title-block" style="text-align: center;">
                        <h3 class="title tname hindi" *ngIf="share.home.language == 'Hindi'">हरियाणा पाँचाल ब्राह्मण महासभा (रजि०)</h3>
                        <h3 class="title tname" *ngIf="share.home.language == 'English'">Haryana Panchal Brahmin Mahasabha (Redg.)</h3>
                        <div style="font-size:1rem" class="hindi" *ngIf="share.home.language == 'Hindi'">1728, गुरुद्वारा रोड़, जवाहर कॉलोनी, एन.आई.टी. फरीदाबाद - 121005</div>
                        <div style="font-size:1rem" *ngIf="share.home.language == 'English'">1728, Gurudwara Road, Jawahar Colony, NIT Faridabad - 121005</div>
                    </div>
                </div>
                <aside class="col-12 col-md-12 widget social">
                    <!-- <div class="title-block">
                        <h3 class="title" *ngIf="share.home.language == 'English'">Connect with us on social media</h3>
                        <h3 class="title hindi" *ngIf="share.home.language == 'Hindi'">सोशल मीडिया पर हमसे जुड़ें</h3>
                    </div> -->
                    <div class="social-list" style="text-align: center;">
                        <a class="icon icon-facebook" target="_blank" href="https://www.facebook.com/HPBMFARIDABAD/"><i
                                class="fab fa-facebook-f"></i></a>
                    </div>
                    <div class="clearfix"></div>
                </aside>
            </div>
        </div>
    </div>
    <!-- .footer-top -->

    <div class="footer-bottom">
        <div class="container">
            <div class="copyright col-xs-12 col-sm-12 col-md-12">
                Designed & Developed by <a href="https://aumswow.com/">AumsWow Wellness Pvt. Ltd.</a>
            </div>
        </div>
    </div>
    <!-- .footer-bottom -->
</footer>