import { Component, OnInit } from '@angular/core';
import { ShareService } from '../../common/share.service'

@Component({
  selector: 'app-ek-rishi',
  templateUrl: './ek-rishi.component.html',
  styleUrls: ['./ek-rishi.component.css']
})
export class EkRishiComponent implements OnInit {

  constructor(public share: ShareService) { }

  ngOnInit(): void {
  }

}
