import { Component, OnInit } from '@angular/core';
import { ShareService } from '../common/share.service';
import { DataService } from '../common/data.service'
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public share: ShareService, private ds: DataService, private http: HttpClient) { }

  ngOnInit(): void {
    this.getIP();
  }

  getIP() {
    this.http.get("https://api.ipify.org/?format=json")
      .subscribe(data => {
        if (data) {
          const mapped = Object.keys(data).map(key => ({ type: key, ip: data[key] }));
          if (mapped[0].ip) {
            this.insertVisitors(mapped[0].ip)
          }
        }
      })
  }

  insertVisitors(i) {
    this.ds.createService(environment.apiURL + "GenericSqlDB/PostExceProcNonQuery",
      {
        ProcedureName: 'InsertVisitors', SqlParamsArr: [
          { ParamName: '@IPAddress', ParamValue: i }]
      }).subscribe
      (data => {
        if (data && data > 0) {
        }
        else {
        }
      });
  }

}
