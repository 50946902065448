import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { ShareService } from '../../common/share.service';
import { DataService } from '../../common/data.service';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbModalRef, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mahasabha-events',
  templateUrl: './mahasabha-events.component.html',
  styleUrls: ['./mahasabha-events.component.css']
})
export class MahasabhaEventsComponent implements OnInit {

  events: any;
  modal: NgbModalRef;

  constructor(private router: Router,
    public share: ShareService,
    private ds: DataService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getMahasabhaEvents();
  }

  getMahasabhaEvents() {
    this.spinner.show()
    this.ds.createService(environment.apiURL + "GenericSqlDB/ExecuteProcedureMultiPostJSON",
      {
        ProcedureName: 'GetEvents', SqlParamsArr: [
          { ParamName: '@UserID', ParamValue: 0 }]
      }).subscribe
      (data => {
        if (data && data.length > 0) {
          this.events = data;
        }
        else {
        }
        this.spinner.hide()
      });
  }

  openmodal(modal) {
    this.modal = this.modalService.open(modal, { keyboard: false, centered: true, size: 'lg' });
  }

  goToGallery(slug) {
    this.modal.close()
    this.router.navigate(['gallery/' + slug])
  }
}