<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;सम्पर्क
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">हरियाणा पाँचाल ब्राह्मण महासभा (रजि०)</h1>
    <p>
        1728, गुरुद्वारा रोड़, जवाहर कॉलोनी, एन.आई.टी. फरीदाबाद - 121005<br/> ई-मेल - panchal<strong>brahmin</strong>mahasabha@gmail.com
        <br/>वेब - www.panchal<strong>brahmin</strong>mahasabha.com
    </p>
    <div class="row">
        <div class="col-12 col-md-4 col-lg-4">
            <h3 class="annexTitle">प्रधान - श्री अशोक कुमार पाँचाल</h3>
            <p>मो०: 9899810002<br/>फ़रीदाबाद</p>
        </div>
        <div class="col-12 col-md-4 col-lg-4">
            <h3 class="annexTitle">महासचिव - श्री अत्तर सिंह पाँचाल</h3>
            <p>मो०: 8383881319<br/>फ़रीदाबाद</p>
        </div>
        <div class="col-12 col-md-4 col-lg-4">
            <h3 class="annexTitle">कोषाध्यक्ष - श्री विक्रम पाँचाल</h3>
            <p>मो०: 9971996687<br/>फ़रीदाबाद</p>
        </div>
    </div>
</div>
<div class="container" *ngIf="share.home.language == 'English'">
    <h1 class="pageTitle">Shree Vishwakarma - Ek Dev</h1>
</div>
<app-footer></app-footer>