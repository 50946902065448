<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;महासभा&nbsp;<i class="fas fa-angle-right"></i>&nbsp;आयोजन
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">हरियाणा पाँचाल ब्राह्मण महासभा - आयोजन</h1>
    <table class="table table-bordered table-condensed table-striped hindi">
        <thead>
            <tr>
                <th>क्र०</th>
                <th>दिनांक</th>
                <th>कार्यक्रम</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let event of events; let i = index;">
                <td>{{i+1}}</td>
                <td>{{event.EventDate}}</td>
                <td>{{event.Title}}</td>
                <td>
                    <span class="tableLink" *ngIf="event.Caption && event.Caption?.length > 0" (click)="openmodal(modal)">और पढ़ें</span>
                </td>
                <td>
                    <span class="tableLink" *ngIf="event.ImgCount && event.ImgCount > 0" [routerLink]="'/gallery/' + event.Slug">गैलरी देखें</span>
                </td>
                <ng-template #modal let-d="close">
                    <div class="modal-header hindi" style="padding: 20px 10px !important; padding-bottom: 10px !important">
                        <h5 style="margin-bottom: 0px !important; width: 100%; font-weight: bold;">{{event.Title}}
                            <span></span>
                            <i (click)="d('Cross click')" class="fas fa-times" style="float: right; font-size: 0.8rem !important;"></i>
                        </h5>
                    </div>
                    <div class="modal-body hindi">
                        <div [innerHTML]="event.Caption"></div>
                        <div class="tableLink" *ngIf="event.ImgCount && event.ImgCount > 0" (click)="goToGallery(event.Slug)">गैलरी देखें</div>
                    </div>
                    <div class="modal-footer aboutModal likeModal">
                    </div>
                </ng-template>
            </tr>
        </tbody>
    </table>
</div>
<div class="container" *ngIf="share.home.language == 'English'">
    <h1 class="pageTitle">Shree Vishwakarma - Shabd Parichay</h1>
</div>
<app-footer></app-footer>