<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;विश्वकर्मा&nbsp;<i class="fas fa-angle-right"></i>&nbsp;जन्म दिवस व पूजा दिवस
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">भगवान श्री विश्वकर्मा - जन्म दिवस व पूजा दिवस</h1>
    <div class="row">
        <div class="col-12 col-lg-5 col-md-5">
            <img style="width: 100%; margin-bottom: 30px;" src="assets/images/HPBM-Shree-Vishwakarma-Janm-Diwas-Pooja-Diwas.jpeg" />
        </div>
        <div class="col-12 col-lg-7 col-md-7">
            <p>वृद्ध-वशिष्ठ पुराण में भगवान श्री विश्वकर्मा जी की जन्मोत्सव माघ मास की त्रयोदशी तिथि के पुनर्वसु नक्षत्र के अट्ठाईसवें अंश मे मानी गयी है। यथा:
                <br/>
                <span class="shlok">माघे शुकले त्रयोदश्यां दिवापुष्पे पुनर्वसौ।<br/> अष्टा र्विशति में जातो विशवकमॉ भवनि च।।</span>
            </p>
            <p>भगवान श्री विश्वकर्मा की जन्मोत्सव वर्षा ऋतु के अन्त और शरद ऋतु के प्रारम्भ में भी मनाए जाने की परम्परा रही है। ज्योतिष शास्त्र के अनुसार इसी दिन सूर्य कन्या राशि में प्रवेश करते हैं। क्योंकि सूर्य की गति अंग्रेजी तारीख से सम्बन्धित है, इसलिए
                कन्या संक्रान्ति भी प्रतिवर्ष 17 सितम्बर को ही पड़ती है। जैसे मकर संक्रान्ति साधारणतः 14 जनवरी को ही पड़ती है। ठीक उसी प्रकार कन्या संक्रान्ति भी प्राय: 17 सितम्बर को ही पड़ती है। इसलिए विश्वकर्मा दिवस भी 17 सितम्बर को ही मनाया जाता है।</p>
            <p>कई लोग विश्वकर्मा जी की पूजा दिवाली के अगले दिन यानि गोवर्धन पूजा के दिन करते हैं। 1 मई को श्रम दिवस के रूप में मनाया जाता है, उसको भी विश्वकर्मा पूजा दिवस कहा जा सकता है।</p>
            <p>उत्तर प्रदेश, बिहार, पश्चिम बंगाल, कर्नाटक, दिल्ली आदि राज्यों में भगवान श्री विश्वकर्मा की भव्य मूर्ति स्थापित करके उसकी पूजा की जाती है।</p>
            <p>विश्वकर्मा पूजा के दिन विशेष रूप से औद्योगिक क्षेत्रों, फैक्ट्रियों, लोहे की दुकानों, वाहन शोरूम, सर्विस सेंटर आदि में पूजा होती है। इस दिन मशीनों को साफ किया जाता है। उनका रंग रोगन होता है और पूजा की जाती है। इस दिन अधिकतर कल-कारखाने बन्द
                रहते हैं क्योंकि विश्वकर्मा पूजन के दिन मशीनों पर काम करना वर्जित माना जाता है।</p>
        </div>
    </div>
</div>
<div class="container" *ngIf="share.home.language == 'English'">
    <h1 class="pageTitle">Shree Vishwakarma - Janm Diwas and Pooja Diwas</h1>
</div>
<app-footer></app-footer>