import { Component, OnInit } from '@angular/core';
import { ShareService } from '../../common/share.service';

@Component({
  selector: 'app-ek-dev',
  templateUrl: './ek-dev.component.html',
  styleUrls: ['./ek-dev.component.css']
})
export class EkDevComponent implements OnInit {

  constructor(public share: ShareService) { }

  ngOnInit(): void {
  }

}
