import { Component, OnInit } from '@angular/core';
import { ShareService } from '../../common/share.service';

@Component({
  selector: 'app-shabd-parichay',
  templateUrl: './shabd-parichay.component.html',
  styleUrls: ['./shabd-parichay.component.css']
})
export class ShabdParichayComponent implements OnInit {

  constructor(public share: ShareService) { }

  ngOnInit(): void {
  }

}
