<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;सदस्य&nbsp;<i class="fas fa-angle-right"></i>&nbsp;एरिया प्रभारी
    </div>
</div>
<div class="container" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">एरिया प्रभारी</h1>
    <table class="table table-bordered table-condensed table-striped hindi">
        <thead>
            <tr>
                <th>क्र०</th>
                <th>एरिया</th>
                <th>प्रभारी</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let member of members; let i = index;">
                <td>{{i+1}}</td>
                <td>{{member.Area}}</td>
                <td>{{member.NameHindi}}</td>
            </tr>
        </tbody>
    </table>
</div>
<app-footer></app-footer>