<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;विश्वकर्मा&nbsp;<i class="fas fa-angle-right"></i>&nbsp;शब्द परिचय
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">भगवान श्री विश्वकर्मा - शब्द परिचय</h1>
    <p>
        वेदों में इस विश्व की रचना व सृजन करने वाली आद्या शक्ति को भगवान श्री विश्वकर्मा के नाम से पुकारा गया। विश्वकर्मा दो शब्दों से बना है, विश्व + कर्मा। पहले हम विश् शब्द की व्याख्या करतें हैं। विश् (अर्थ- प्रवेश) धातु से विश्व शब्द सिद्ध होता है। ‘कर्म’
        का अर्थ है ‘कार्य’ या ‘क्रिया’। वे सारी क्रियाएँ जो न सिर्फ हम शरीर द्वारा करते हैं अपितु अपने मन और वाणी द्वारा भी करते हैं, उसे कर्म कहते हैं।
    </p>
    <p>
        अतः विश्वकर्मा का अर्थ इस प्रकार हुआ, जो विश्व में समस्त कार्य अथवा क्रियाओं का निष्पादन करते हैं उन्हें विश्वकर्मा कहते हैं।
    </p>
    <div class="inMatterImg">
        <img src="assets/images/HPBM-Shree-Vishwakarma-Sand-Art-Sudharshan-Pattnaik.jpg" />
    </div>

    <p class="shlok">
        सर्वस्य कर्ता विश्वकर्मा।
    </p>
    <p class="shlokSource">निरुक्त, दैवत काण्ड, अध्याय 10 खण्ड 16 </p>
    <p>सर्व प्रकार के उत्तमोत्तम शिल्पीय पदार्थों की रचना करने वाले ब्राह्मण को विश्वकर्मा कहते हैं। अतः विश्वकर्मा का अर्थ पूर्णशिल्पी है। </p>
    <p>
        यहाँ तक ही नहीं ऋग्वेद मण्डल 10 अध्याय 81 मन्त्र प्रथम में विश्वकर्मा के विषय में स्पष्ट रुप से आद्याशक्ति कोई काल्पनिक नहीं है। यथा:
    </p>
    <p class="shlok">
        य इमा विश्वा भुवानानि जुह्रहषिर्हाता न्यसीदत् पिता नः।<br /> स आशिषा द्रविणमिच्छमानः प्रथमच्छदवरां आविवेश।।1।।<br/>किं स्विदासीदधिष्ठानमारम्भणं कतमत्स्वित्क्यासीत्।<br /> यतों भमि जनयान्विश्वकर्मा वि द्यामौर्णोन्महिना विश्वचक्षाः।।2।।
    </p>
    <p class="shlokSource">ऋग्वेद मण्डल 10 अध्याय 81 मन्त्र प्रथम</p>
    <p>
        विश्वकर्मा शब्द वैदिक है और यास्क मुनि ने अपने निरुक्त और निघन्टु वेदार्थ में विश्वकर्मा शब्द की व्याख्या व निरुपण करते हुए यूँ कहा है कि आत्मा, त्वष्टा, प्रजापति, आदित्य, वाक्, प्राण आदि विश्वकर्मा के ही वाचक हैं। शतपथ ब्राह्मण में वाक् वै विश्वकर्मा,
        मन्त्र “वै” विश्वकर्मा, शब्द “वै” विश्वकर्मा, छंद “वै” विश्वकर्मा अर्थात वाक्, छन्द, प्राण, शब्द को ही विश्वकर्मा कहते हैं।
    </p>
</div>
<div class="container" *ngIf="share.home.language == 'English'">
    <h1 class="pageTitle">Shree Vishwakarma - Shabd Parichay</h1>
</div>
<app-footer></app-footer>