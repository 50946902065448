import { Component, OnInit } from '@angular/core';
import { ShareService } from '../common/share.service';
import { DataService } from '../common/data.service';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-download-member-id',
  templateUrl: './download-member-id.component.html',
  styleUrls: ['./download-member-id.component.css']
})
export class DownloadMemberIdComponent implements OnInit {

  memberID: string = "";
  memberDetails: any = [];

  constructor(public share: ShareService, private ds: DataService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }

  getMemberDetails() {
    this.spinner.show();
    this.ds.createService(environment.apiURL + "GenericSqlDB/PostExceProcTable",
      {
        ProcedureName: 'GetMemberDetailsForID', SqlParamsArr: [
          { ParamName: '@Referrer', ParamValue: window.location.origin },
          { ParamName: '@MemberID', ParamValue: this.memberID },
        ]
      }).subscribe
      (data => {
        if (data && data > 0) {
          this.memberDetails = data;
        }
        else {
          this.memberDetails = [];
        }
        this.spinner.hide()
      });
  }

}
