import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ShareService } from '../common/share.service';
import { DataService } from '../common/data.service';
import { environment } from '../../environments/environment';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-member-latest',
  templateUrl: './add-member-latest.component.html',
  styleUrls: ['./add-member-latest.component.css']
})
export class AddMemberLatestComponent implements OnInit {

  MembershipForm: FormGroup;

  imageChangedEventAF: any = '';
  croppedImageAF: any = '';

  imageChangedEventAB: any = '';
  croppedImageAB: any = '';

  imageChangedEventLive: any = '';
  croppedImageLive: any = '';

  aadharFront: any;
  aadharBack: any;
  livePhoto: any;
  showImgCropper = [false, false, false]
  submitted: boolean = false;

  constructor(public share: ShareService, private fb: FormBuilder, private ds: DataService,
    private spinner: NgxSpinnerService, private modalService: NgbModal, private http: HttpClient) { }

  ngOnInit(): void {
    this.MembershipForm = this.fb.group({
      salutation: ['', Validators.required],
      name: ['', Validators.required],
      dob: ['', Validators.required],
      aadhar: ['', Validators.required],
      mobile: ['', [Validators.required]],
      email: [''],
      address: [''],
      city: ['', Validators.required],
      district: [''],
      state: ['', Validators.required],
      pin: [''],
      fatherName: ['', Validators.required]
    })
  }

  submitForm() {
    if (this.MembershipForm.touched && this.MembershipForm.valid && this.showImgCropper[0]
      && this.showImgCropper[1] && this.showImgCropper[2]) {
      this.spinner.show()
      let a = this.dataURItoBlob(this.croppedImageAF);
      let image = new File([a], "AadharCard-Front-", { type: 'image/jpeg' });
      let formData = new FormData();
      formData.append('image', image, image.name);
      this.http.post(environment.apiURL + 'GenericSqlDB/UploadMemberImages', formData)
        .subscribe(res => {
          this.aadharFront = res
          let b = this.dataURItoBlob(this.croppedImageAB);
          let image1 = new File([b], "AadharCard-Back-", { type: 'image/jpeg' });
          let formData1 = new FormData();
          formData1.append('image', image1, image1.name);
          this.http.post(environment.apiURL + 'GenericSqlDB/UploadMemberImages', formData1)
            .subscribe(res1 => {
              this.aadharBack = res1
              let c = this.dataURItoBlob(this.croppedImageLive);
              let image2 = new File([c], "Member-", { type: 'image/jpeg' });
              let formData2 = new FormData();
              formData2.append('image', image2, image2.name);
              this.http.post(environment.apiURL + 'GenericSqlDB/UploadMemberImages', formData2)
                .subscribe(res2 => {
                  this.livePhoto = res2
                  this.ds.createService(environment.apiURL + "GenericSqlDB/PostExceProcNonQuery",
                    {
                      ProcedureName: 'InsertMembersLatest', SqlParamsArr: [
                        { ParamName: '@Referrer', ParamValue: window.location.origin },
                        { ParamName: '@Salutation', ParamValue: this.MembershipForm.controls.salutation.value },
                        { ParamName: '@Name', ParamValue: this.MembershipForm.controls.name.value },
                        { ParamName: '@DOB', ParamValue: this.MembershipForm.controls.dob.value },
                        { ParamName: '@AadharNo', ParamValue: this.MembershipForm.controls.aadhar.value },
                        { ParamName: '@MobileNo', ParamValue: this.MembershipForm.controls.mobile.value },
                        { ParamName: '@EmailID', ParamValue: this.MembershipForm.controls.email.value },
                        { ParamName: '@Address', ParamValue: this.MembershipForm.controls.address.value },
                        { ParamName: '@City', ParamValue: this.MembershipForm.controls.city.value },
                        { ParamName: '@District', ParamValue: this.MembershipForm.controls.district.value },
                        { ParamName: '@State', ParamValue: this.MembershipForm.controls.state.value },
                        { ParamName: '@PIN', ParamValue: this.MembershipForm.controls.pin.value },
                        { ParamName: '@FatherName', ParamValue: this.MembershipForm.controls.fatherName.value },
                        { ParamName: '@AadharCardFront', ParamValue: this.aadharFront },
                        { ParamName: '@AadharCardBack', ParamValue: this.aadharBack },
                        { ParamName: '@MemberPhoto', ParamValue: this.livePhoto }
                      ]
                    }).subscribe
                    (data => {
                      if (data && data > 0) {
                        window.alert("Thank you! We have received your Details. Our Team will contact you shortly.");
                        this.MembershipForm.reset();
                        window.location.reload();

                      }
                      else {
                      }
                      this.spinner.hide()
                    });
                })
            })
        })
    }
    else {
      window.alert("The fields with * mark are mandatory.")
    }
  }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  fileChangeEventAF(event: any): void {
    this.imageChangedEventAF = event;
    this.showImgCropper[0] = true;
  }

  fileChangeEventAB(event: any): void {
    this.imageChangedEventAB = event;
    this.showImgCropper[1] = true;
  }

  fileChangeEventLive(event: any): void {
    this.imageChangedEventLive = event;
    this.showImgCropper[2] = true;
  }

  imageCroppedAF(event: ImageCroppedEvent) {
    this.croppedImageAF = event.base64;
  }

  imageCroppedAB(event: ImageCroppedEvent) {
    this.croppedImageAB = event.base64;
  }

  imageCroppedLive(event: ImageCroppedEvent) {
    this.croppedImageLive = event.base64;
  }

  imageLoaded() {
  }

  cropperReady() {
    // cropper ready

  }
  loadImageFailed() {
    // show message
  }

  imageLoadedLive(event: any) {
  }

  cropperReadyLive() {
    // cropper ready

  }
  loadImageFailedLive() {
    // show message
  }

  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI.replace(/^data:image\/(png|jpeg);base64,/, ""));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  convert(event: any) {
    var file = event.target.files[0];
    this.getBase64(file).then(
      data => console.log(data)
    );
  }

}
