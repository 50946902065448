<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;विश्वकर्मा&nbsp;<i class="fas fa-angle-right"></i>&nbsp;वंशावली
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">भगवान श्री विश्वकर्मा - वंशावली</h1>
    <div class="row">
        <div class="col-12 col-lg-5 col-md-5">
            <img style="width: 100%; margin-bottom: 30px;" src="assets/images/HPBM-Shree-Vishwakarma-Vanshawali.jpeg" />
        </div>
        <div class="col-12 col-lg-7 col-md-7">
            <p>
                स्कन्दपुराण के नागर खण्ड में श्री विश्वकर्मा के वशंजों की चर्चा की गई है।<br/> उनके पुत्रों के नाम है –<br/>1. महर्षि मनु<br/>2. महर्षि मय<br/>3. महर्षि त्वष्टा<br/>4. महर्षि शिल्पी<br/>5. महर्षि देवज्ञ।
            </p>
            <p>
                श्री विश्वकर्मा जी के सबसे बडे पुत्र महर्षि मनु का विवाह अंगिरा ऋषि की कन्या कंचना के साथ हुआ था। यथा:
            </p>
            <p class="shlok">मुनेरांगिरस: पुत्री प्रसिद्धा काँचना वया।<br/> विश्वकर्मा तनुजस्य मनो: पत्नी बभूवसा।।</p>
            <p class="shlokSource">श्री चंद्रदेव शास्त्री कृत शिल्पदर्शन</p>
            <p>
                इन्होने मानव सृष्टि का निर्माण किया है। इनके कुल में अग्निगर्भ, सर्वतोमुख, ब्रम्ह आदि ऋषि उत्पन्न हुये हैं। ये लोहे के कर्म के उद्धगाता हैं। इनके वशंज लोहकार के रुप मे जाने जाते हैं।
            </p>
            <p>
                श्री विश्वकर्मा जी के दूसरे पुत्र महर्षि मय का विवाह पारासर ऋषि की कन्या सौम्या देवी के साथ हुआ था। यथा:
            </p>
            <p class="shlok">पाराशर मुने: पुत्री सोम्यादेवी सुलोचना।<br/> विश्वकर्मतनुजस्य मयस्यापि कुलाँगना।।</p>
            <p class="shlokSource">श्री चंद्रदेव शास्त्री कृत शिल्पदर्शन</p>
        </div>
    </div>
    <p>
        इन्होने इन्द्रजाल सृष्टि की रचना की है। इनके कुल में विष्णुवर्धन, सूर्यतन्त्री, तंखपान, ओज, महोज इत्यादि महर्षि पैदा हुए हैं। ये बढ़ई के कर्म के उद्धगाता हैं। इनके वंशज काष्टकार के रुप में जाने जाते हैं।
    </p>
    <p>
        श्री विश्वकर्मा जी के तीसरे पुत्र महर्षि त्वष्ठा का विवाह कौशिक ऋषि की कन्या जयन्ती के साथ हुआ था। यथा:
    </p>
    <p class="shlok">कौशिकस्य मुने: पुत्री जयन्तीति च विश्रुता।<br/> विश्वकर्मतनुजस्य भार्या त्वष्टुरभतस्वयम्।।</p>
    <p class="shlokSource">श्री चंद्रदेव शास्त्री कृत शिल्पदर्शन</p>
    <p>
        इनके कुल में लोक त्वष्ठा, तन्तु, वर्धन, हिरण्यगर्भ शुल्पी अमलायन ऋषि उत्पन्न हुये हैं। वे देवताओं में पूजित ऋषि थे। इनके वंशज ताम्रक के रूप में जाने जाते हैं।
    </p>
    <p>
        श्री विश्वकर्मा जी के चौथे पुत्र महर्षि शिल्पी का विवाह भृगु ऋषि की करूणा के साथ हुआ था। यथा:
    </p>
    <p class="shlok">विश्वकर्मतनुजस्य पत्नी तक्षस्य चाभवत्।<br/> करुणा नाम विख्याता ब्रह्मर्षिस्तनया मृगो:।।</p>
    <p class="shlokSource">श्री चंद्रदेव शास्त्री कृत शिल्पदर्शन</p>
    <p>
        इनके कुल में वृद्धि, ध्रुन, हरितावश्व, मेधवाह नल, वस्तोष्यति, शवमुन्यु आदि ऋषि हुये हैं। इनकी कलाओं का वर्णन मानव जाति तो क्या देवगण भी नहीं कर पाये हैं। इनके वशंज शिल्पकला के अधिष्ठाता हैं और इन्हें मूर्तिकार भी कहते हैं।
    </p>
    <p>
        श्री विश्वकर्मा जी के पाँचवे पुत्र महर्षि देवज्ञ का विवाह जैमिनी ऋषि की कन्या चन्र्दिका के साथ हुआ था। यथा:
    </p>
    <p class="shlok">कुमारी जैमिने: साध्वी चन्द्रिका नाम विश्रुता।।<br/> विश्वकर्मतनुजस्य विश्वज्ञस्य तथैव च।।</p>
    <p class="shlokSource">श्री चंद्रदेव शास्त्री कृत शिल्पदर्शन</p>
    <p>
        इनके कुल में सहस्त्रातु, हिरण्यम, सूर्यगोविन्द, लोकबान्धव, अर्कषली इत्यादी ऋषि हुये हैं। इनके वशंज स्वर्णकार के रूप में जाने जाते हैं। ये रजत व स्वर्ण धातु के शिल्पकर्म करते है।
    </p>
    <p>
        विश्वकर्मा वंशीय ब्राह्मणों के नाम भेद विश्वकर्मा वंश भास्कर पृष्ठ 111 पर संक्षेप में इस प्रकार लिखे हैं। यथा:
    </p>
    <p class="shlok">
        विश्वकर्मा कुले जाता: शिल्पिन: ब्रह्मणास्मृता:।<br/> तथा च तेषाम संक्षेपाद नामानि प्रवदाभ्यहम्।।<br/> धीमान, जाँगिडोपाध्याय, टाँक, लाहोरी, माथुर:।<br/> सूत्रधारों, कुकहासो, रामगढ़िया, मैथिल:।।<br/> त्रिवेदी, पिप्पला, लोष्ठा, महूलियाश्च, रावत:।।
        <br/> कान्याकुब्ज, मालवीयो, मागधश्च पञ्चालर:।।<br/> सरवरियाश्च, गौडो, देवकमलारो, तथा।<br/> विश्वब्राह्मण:, कंशाली, विश्वकर्मा, नवनोन्दन:।।<br/> तरंचशैव:, पाञ्चालो, आचार्यश्च, जगदगुरू:।<br/> एतानि शिल्प विप्राणाम नामानि त्रिंशत स्मृता:।।
        <br/>
    </p>
    <p class="shlokSource">विश्वकर्मा वंश भास्कर पृष्ठ 111</p>
    <p>अर्थ: धीमान, जाँगिड, उपाध्याय (ओझा), टाँक (तक्षा), लाहौरी, मथुरिया, सूत्रधार, कूकहास, रामगढ़िया, मैथिल, त्रिवेदी, पिप्पला (पलोटा), लोष्ठा (लोटा), महोलिया, रावत, कान्यकुब्ज, मालवीय, मागध, पँचालर, सरवरिया, गौड़, देवकमलार, विश्व ब्राह्मण, कंसाली, विश्वकर्मा,
        नवनन्दन (नवन्दन), तरंचशैव, पाँचाल, आचार्य, एवम् जगदगुरू। इस प्रकार शिल्पी ब्राह्मणों के 30 (तीस) भेद हैं।
    </p>
</div>
<div class="container" *ngIf="share.home.language == 'English'">
    <h1 class="pageTitle">Shree Vishwakarma - Vanshawali</h1>
</div>
<app-footer></app-footer>