import { Component, OnInit } from '@angular/core';
import { ShareService } from '../../common/share.service';
import { DataService } from '../../common/data.service';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnInit {

  members: any;

  constructor(public share: ShareService,
    private ds: DataService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getExecutiveCommittee();
  }

  getExecutiveCommittee() {
    if (this.share.members.common && this.share.members.common.length > 0) {
      this.members = this.share.members.common;
    }
    else {
      this.spinner.show()
      this.ds.createService(environment.apiURL + "GenericSqlDB/PostExceProcTable",
        {
          ProcedureName: 'GetCommonMembers', SqlParamsArr: [
            { ParamName: '@UserID', ParamValue: 0 }]
        }).subscribe
        (data => {
          if (data && data.length > 0) {
            this.members = data;
            this.share.members.common = data;
          }
          else {
          }
          this.spinner.hide()
        });
    }
  }
}