import { Component, OnInit } from '@angular/core';
import { ShareService } from '../common/share.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  mobileNav: boolean = false;

  constructor(public share: ShareService) { }

  ngOnInit(): void {
  }

  toggleMobileNav() {
    this.mobileNav = !this.mobileNav;
  }

  switchLanguage(i) {
    this.share.home.language = i;
    localStorage.setItem("hpbm-lang", i);
  }

}
