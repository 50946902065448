import { Component, OnInit } from '@angular/core';
import { ShareService } from '../../common/share.service'

@Component({
  selector: 'app-ek-upadhi',
  templateUrl: './ek-upadhi.component.html',
  styleUrls: ['./ek-upadhi.component.css']
})
export class EkUpadhiComponent implements OnInit {

  constructor(public share: ShareService) { }

  ngOnInit(): void {
  }

}
