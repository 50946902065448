<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;विश्वकर्मा&nbsp;<i class="fas fa-angle-right"></i>&nbsp;एक ऋषि
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">भगवान श्री विश्वकर्मा - एक ऋषि</h1>
    <p>विश्वकर्मा नाम के ऋषि भी हुए हैं। ऋग्वेद में विश्वकर्मा सूक्त दिया हुआ है, और इस सूक्त में 14 ऋचायें है इस सूक्त के देवता विश्वकर्मा हैं और मन्त्र दृष्टा ऋषि भी विश्वकर्मा हैं।</p>
    <p>ऋग्वेद के दशम मण्डल के सूक्त 81 व 82 दोनों सूक्त विश्वकर्मा सूक्त है। इनमें प्रत्येक में सात-सात मन्त्र है। इन सब मन्त्रों के ऋषि और देवता भुवनपुत्र विश्वकर्मा ही हैं। ये ही चौदह मन्त्र यजुर्वेद के 17वें अध्याय में मन्त्र 17 से 32 तक आते हैं, जिसमें
        से दो मन्त्र 24वां और 32वां विशेष रूप से महत्त्वपूर्ण है। प्रत्येक माँगलिक पर्व यज्ञ में गृह प्रवेश करते समय, किसी भी नवीन कार्य के शुभारम्भ पर, विवाह आदि संस्कारों के समय इनका पाठ अवश्य करना चाहिए।</p>
    <div class="inMatterImg">
        <img src="assets/images/HPBM-Shree-Vishwakarma-Ek-Rishi.jpeg" />
    </div>
    <p>ऋग्वेद दशम मण्डल सूक्त 81</p>
    <p class="shlok">
        य इमा विश्वा भुवानानि जुह्रहषिर्हाता न्यसीदत् पिता नः।<br /> स आशिषा द्रविणमिच्छमानः प्रथमच्छदवरां आविवेश।।1।।<br /> किं स्विदासीदधिष्ठानमारम्भणं कतमत्स्वित्क्यासीत्।<br /> यतों भमि जनयान्विश्वकर्मा वि द्यामौर्णोन्महिना विश्वचक्षाः।।2।।
        <br /> विश्वतश्चक्षुरूत विश्वतोमुख विश्वतोबाहुरूत विश्वतस्पात्।<br /> सं बाहुभ्यां धमति सं पतत्रैर्द्यावाभूमी जनयन्देव एक।।3।।<br /> किं स्विंद्नं क उ स बृक्ष आस यतो द्यावापृथिवि निष्टतक्षुः।<br /> मनीषिणो मनसा पृच्छतेदु तद्यदध्यतिष्ठद् भुमानानि
        धारयन्।।4।।
        <br /> या ते धामानि परमाणि यावमा या मध्यमा विश्वकर्मभुतेमा।<br /> शिक्षा सखिभ्यो ह्रविषि स्वधावः स्वयं यजस्व तन्त्रं बृधानः।।5।।<br /> विश्वकर्मन् ह्रविषा वातृधानः स्वयं य़जस्वपृथिवीमुत धाम्।<br /> मुह्मन्त्वन्ये अभितो जनास इह्रास्माकं मधवा सूरिरस्तु।।6।।<br
        /> वाचस्पति विश्वकर्माणमूतये मनोजुवं वाजे अद्या हुवेम।<br /> स नो विश्वानि हवानानि जोषद् विश्वशम्भूरवसे साधुकर्मा।।7।।<br />
    </p>
    <p>ऋग्वेद दशम मण्डल सूक्त 82</p>
    <p class="shlok">
        चक्षुषः पिता मनसा हि धीरो घृतमेने अजनन्भम्नमाने।<br /> यद्रेदन्ता अद्रछह्रन्त पूर्व आदिद् द्यावापृथिवी अप्रथेताम्।।1।।<br /> विश्वकर्मा विमना आदिह्राया धाता विधाता परमोत सन्छक्।<br /> तेषामिष्टनि समिया मदन्ति यत्रा सप्त पर एकमाहुः।।2।।<br /> यो
        नः पिता जनिया यो विधाता धामानि वेद भुवानानि विश्वा।<br /> यो देवानां नामधा एक एवम् तं सप्रंश्नम्भुवना यन्त्यन्या।।3।।<br /> त आयजन्त द्रविणं समस्मा ऋषयः पूर्वे जरितारों न भूना।<br /> असूर्ते सूर्ते रजसि निषते ये भूतानि समकृण्वन्निमानी।।4।।
        <br /> परो दिवा पर एना पृथिव्या परो देवेभिरसुरैर्यदस्ति।<br /> कं स्विद् गर्भ प्रथंम दध्र आपो यत्र देवाः समपश्चन्त पूर्वे।।5।।<br /> तमिद्गर्भ प्रथमं दध्र आपो यत्र देवाः समगच्छन्त विश्वे।<br /> अजस्य नाभावध्योकमर्पितं यस्मिन्विश्वानि भुवानि तस्थुः।।6।।<br
        /> न तं विदाथ य इमा जजानाSन्यद्युष्माकमन्तंर बभूव।<br /> नीहारेण प्रावृता जल्प्या चाSसुतृप उक्थाशासश्चरन्ति।।7।।<br />
    </p>
    <p>यजुर्वेद के 17वें अध्याय का 24वां महत्वपुर्ण मन्त्र</p>
    <p class="shlok">
        विश्वकर्मन् ह्रविषा वर्धनेन त्रातारमिन्द्रमकृणोरवध्यम्।<br /> तस्मै विसः समनमन्त पर्वीरयमुग्रो विह्रव्या यथाSसत्।।24।।<br />
    </p>
    <p>यजुर्वेद के 17वें अध्याय का 32वां महत्वपुर्ण मन्त्र</p>
    <p class="shlok">
        विश्वकर्मा ह्राजनिष्ट देव आदिद् गन्धर्वो अभवद् द्वितीयः।<br /> तृतीयः पिता जानितौषधीनामपां गर्भ व्यदधात्पुरूत्रा।।32।।
    </p>
</div>
<div class="container" *ngIf="share.home.language == 'English'">
    <h1 class="pageTitle">Shree Vishwakarma - Ek Rishi</h1>
</div>
<app-footer></app-footer>