<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="container">
    <h1 *ngIf="share.home.language == 'English'" class="pageTitle">Gallery (1969 - 2016)</h1>
    <h1 *ngIf="share.home.language == 'Hindi'" class="pageTitle hindi">गैलरी (1969 - 2016)</h1>
    <div class="btnGroup">
        <button class="btn btn-sm btn-outline-primary albumBtn" routerLink="/gallery">2019 - 2021</button>
        <button class="btn btn-sm btn-outline-primary albumBtn" routerLink="/gallery-2018">2017 - 2018</button>
        <button class="btn btn-sm btn-primary albumBtn" routerLink="/gallery-2016">1969 - 2016</button>
    </div>
    <div class="galleryGroup">
        <div class="galleryBlock hindi" *ngFor="let item of album; let i = index;">
            <!-- <img [src]="share.home.apiURL + 'Uploads/Gallery/Thumbnail/' + item.Thumbnail" /> -->
            <div class="galleryThumb" [style.background]="'url(' + share.home.apiURL + 'Uploads/Gallery/Thumbnail/' + item.Thumbnail + ')'">
            </div>
            <hr>
            <h2>{{item.Title}}</h2>
            <span>Event Date: {{item.EventDate}}</span>
            <div [innerHTML]="item.Caption"></div>
            <button class="btn btn-sm btn-outline-primary viewBtn" [routerLink]="'/gallery/' + item.Slug">View Photos</button>
        </div>
    </div>
</div>
<app-footer></app-footer>