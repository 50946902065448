<header class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <div class="webmenu">
            <div class="logoBox">
                <span *ngIf="share.home.language == 'Hindi'" class="brandName hindi">हरियाणा पाँचाल ब्राह्मण महासभा (रजि०)</span>
                <span *ngIf="share.home.language == 'English'" class="brandName">Haryana Panchal Brahmin Mahasabha (Redg.)</span>
            </div>
            <div class="menuBox">
                <div *ngIf="share.home.language == 'Hindi'" class="navbar-expand">
                    <ul class="navbar-nav hindi">
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/">होम</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" placement="bottom" #vk="ngbPopover" triggers="mouseenter" [ngbPopover]="vishwakarma">विश्वकर्मा <i class="fas fa-chevron-down"></i></a>
                            <ng-template #vishwakarma>
                                <div class="dropDown">
                                    <div routerLink="/vishwakarma-shabd-parichay/">शब्द परिचय</div>
                                    <div routerLink="/vishwakarma-mahaprabhu/">महाप्रभु</div>
                                    <div routerLink="/vishwakarma-ek-rishi/">एक ऋषि</div>
                                    <div routerLink="/vishwakarma-kai-swaroop/">कई स्वरूप</div>
                                    <div routerLink="/vishwakarma-vanshawali/">वंशावली</div>
                                    <div routerLink="/vishwakarma-ek-upadhi/">एक उपाधि</div>
                                    <div routerLink="/vishwakarma-yantro-ke-dev/">यन्त्रों के देव</div>
                                    <div routerLink="/vishwakarma-janm-diwas/">जन्म दिवस व पूजा दिवस</div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" placement="bottom" #ab="ngbPopover" triggers="mouseenter" [ngbPopover]="about">महासभा <i class="fas fa-chevron-down"></i></a>
                            <ng-template #about>
                                <div class="dropDown">
                                    <div routerLink="/about-haryana-panchal-brahmin-mahasabha/">एक झलक</div>
                                    <div routerLink="/events-by-haryana-panchal-brahmin-mahasabha/">आयोजन</div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" placement="bottom" #m="ngbPopover" triggers="mouseenter" [ngbPopover]="members">सदस्य <i class="fas fa-chevron-down"></i></a>
                            <ng-template #members>
                                <div class="dropDown">
                                    <div routerLink="/list-of-hpbm-management-committee/">प्रबन्धन समिति</div>
                                    <div routerLink="/list-of-hpbm-executive-members/">कार्यकारिणी</div>
                                    <div routerLink="/list-of-hpbm-area-prabhari/">एरिया प्रभारी</div>
                                    <div routerLink="/list-of-hpbm-members/">सामान्य सदस्य</div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/gallery/">गैलरी</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/contact-haryana-panchal-brahmin-mahasabha/">सम्पर्क</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link member" routerLink="/new-member-registration/">प्राथमिक सदस्य बनें</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" placement="bottom" #changeLang="ngbPopover" triggers="mouseenter" [ngbPopover]="lang"><img style="max-height: 24px;" src="assets/images/switchLanguage.png" /></a>
                            <ng-template #lang>
                                <div class="langOption">
                                    <div (click)="switchLanguage('Hindi')"><i *ngIf="share.home.language == 'Hindi'" class="fas fa-check"></i> हिन्दी</div>
                                    <div (click)="switchLanguage('English')"><i *ngIf="share.home.language == 'English'" class="fas fa-check"></i> English</div>
                                </div>
                            </ng-template>
                        </li> -->
                        <!-- <div style="position: absolute; right: 10px; top: 9px">
                            <li class="nav-item">
                                <a class="nav-link member" routerLink="/download-member-id/">ID कार्ड डाउनलोड</a>
                            </li>
                        </div> -->
                    </ul>
                </div>
                <div *ngIf="share.home.language == 'English'" class="navbar-expand">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" placement="bottom" #vk="ngbPopover" triggers="mouseenter" [ngbPopover]="vishwakarma">Vishwakarma <i class="fas fa-chevron-down"></i></a>
                            <ng-template #vishwakarma>
                                <div class="dropDown">
                                    <div>History</div>
                                    <div>Vanshawali</div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" placement="bottom" #ab="ngbPopover" triggers="mouseenter" [ngbPopover]="about">Mahasabha <i class="fas fa-chevron-down"></i></a>
                            <ng-template #about>
                                <div class="dropDown">
                                    <div>History</div>
                                    <div>Objectives</div>
                                    <div>Motives</div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/">Members</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/gallery">Gallery</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/">Contact</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" placement="bottom" #changeLang="ngbPopover" triggers="mouseenter" [ngbPopover]="lang"><img style="max-height: 24px;" src="assets/images/switchLanguage.png" /></a>
                            <ng-template #lang>
                                <div class="langOption">
                                    <div (click)="switchLanguage('Hindi')"><i *ngIf="share.home.language == 'Hindi'" class="fas fa-check"></i> हिन्दी</div>
                                    <div (click)="switchLanguage('English')"><i *ngIf="share.home.language == 'English'" class="fas fa-check"></i> English</div>
                                </div>
                            </ng-template>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="mobilemenu">
            <div class="logoBox">
                <span *ngIf="share.home.language == 'Hindi'" class="brandName hindi">हरियाणा पाँचाल ब्राह्मण महासभा (रजि०)</span>
                <span *ngIf="share.home.language == 'English'" class="brandName">Haryana Panchal Brahmin Mahasabha (Redg.)</span>
            </div>
            <div class="navBox">
                <div (click)="toggleMobileNav()" *ngIf="!mobileNav" style="text-align:center; margin: 10px 0"><i class="fas fa-bars"></i></div>
                <div (click)="toggleMobileNav()" *ngIf="mobileNav" style="text-align:center; margin: 10px 0"><i class="fas fa-bars" style="transform: rotate(90deg);"></i></div>
                <div *ngIf="share.home.language == 'English' && mobileNav" class="navMobile">
                    <ul>
                        <li>Home</li>
                        <li>Vishwakarma
                            <ul>
                                <li>History</li>
                                <li>Vanshawali</li>
                            </ul>
                        </li>
                        <li>
                            Mahasabha
                            <ul>
                                <li>History</li>
                                <li>Objectives</li>
                                <li>Motives</li>
                            </ul>
                        </li>
                        <li>Members</li>
                        <li>Gallery</li>
                        <li>Contact</li>
                    </ul>
                </div>
                <div *ngIf="share.home.language == 'Hindi' && mobileNav" class="navMobile hindi">
                    <ul>
                        <li routerLink="/">होम</li>
                        <li>विश्वकर्मा
                            <ul>
                                <li routerLink="/vishwakarma-shabd-parichay/">शब्द परिचय</li>
                                <li routerLink="/vishwakarma-mahaprabhu/">महाप्रभु</li>
                                <li routerLink="/vishwakarma-ek-rishi/">एक ऋषि</li>
                                <li routerLink="/vishwakarma-kai-swaroop/">कई स्वरूप</li>
                                <li routerLink="/vishwakarma-vanshawali/">वंशावली</li>
                                <li routerLink="/vishwakarma-ek-upadhi/">एक उपाधि</li>
                                <li routerLink="/vishwakarma-yantro-ke-dev/">यन्त्रों के देव</li>
                                <li routerLink="/vishwakarma-janm-diwas/">जन्म दिवस व पूजा दिवस</li>
                            </ul>
                        </li>
                        <li>
                            महासभा
                            <ul>
                                <li routerLink="/about-haryana-panchal-brahmin-mahasabha/">एक झलक</li>
                                <li routerLink="/events-by-haryana-panchal-brahmin-mahasabha/">आयोजन</li>
                            </ul>
                        </li>
                        <li>
                            सदस्य
                            <ul>
                                <li routerLink="/list-of-hpbm-management-committee/">प्रबन्धन समिति</li>
                                <li routerLink="/list-of-hpbm-executive-members/">कार्यकारिणी</li>                                    <div routerLink="/list-of-hpbm-area-prabhari/">एरिया प्रभारी</div>
                                <li routerLink="/list-of-hpbm-area-prabhari/">एरिया प्रभारी</li>
                                <li routerLink="/list-of-hpbm-members/">सामान्य सदस्य</li>
                            </ul>
                        </li>
                        <li routerLink="/gallery/">गैलरी</li>
                        <li routerLink="/contact-haryana-panchal-brahmin-mahasabha/">सम्पर्क</li>
                        <li class="member" routerLink="/new-member-registration/">प्राथमिक सदस्य बनें</li>
                        <!-- <li class="member" routerLink="/download-member-id/">ID कार्ड डाउनलोड</li> -->

                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>