<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;प्राथमिक सदस्य बनें
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">प्राथमिक सदस्य बनें</h1>
    <form [formGroup]="MembershipForm">
        <h3 class="subTitle">पर्सनल जानकारी</h3>
        <div class="row">
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">अभिवादन</label>
                    <select class="form-control" formControlName="salutation">
                    <option value="श्री">श्री</option>
                        <option value="श्रीमती">श्रीमती</option>
                        <option value="कुमारी">कुमारी</option>
                        <option value="मास्टर">मास्टर</option>
                        <option value="प्रोफ़ेसर">प्रोफ़ेसर</option>
                        <option value="डॉक्टर">डॉक्टर</option>
                    </select>
                    <div class="error-block" *ngIf="MembershipForm.get('salutation').touched && MembershipForm.get('salutation').errors?.required">यह जानकारी अनिवार्य है।</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">नाम</label>
                    <input type="text" class="form-control" formControlName="name" maxlength="100" />
                    <div class="error-block" *ngIf="MembershipForm.get('name').touched && MembershipForm.get('name').errors?.required">यह जानकारी अनिवार्य है।</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">उपनाम</label>
                    <input type="text" class="form-control" formControlName="surname" maxlength="100" placeholder="विश्वकर्मा, पाँचाल, जाँगिड आदि" />
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">लिंग</label>
                    <select class="form-control" formControlName="gender">
                        <option value="स्त्री">स्त्री</option>
                        <option value="पुरुष">पुरुष</option>
                        <option value="अन्य">अन्य</option>
                    </select>
                    <div class="error-block" *ngIf="MembershipForm.get('gender').touched && MembershipForm.get('gender').errors?.required">यह जानकारी अनिवार्य है।</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">जन्मतिथि</label>
                    <input type="date" class="form-control" formControlName="dob" placeholder="YYYY-MM-DD" />
                    <div class="error-block" *ngIf="MembershipForm.get('dob').touched && MembershipForm.get('dob').errors?.required">यह जानकारी अनिवार्य है।</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">गौत्र</label>
                    <input type="text" class="form-control" formControlName="gotra" maxlength="100" />
                </div>
            </div>
            <div class="col-12" [class.col-lg-3]="MembershipForm.get('maritalStatus').value == 'विवाहित' || MembershipForm.get('maritalStatus').value == 'अन्य'" [class.col-md-3]="MembershipForm.get('maritalStatus').value == 'विवाहित' || MembershipForm.get('maritalStatus').value == 'अन्य'"
                [class.col-lg-6]="MembershipForm.get('maritalStatus').value != 'विवाहित' && MembershipForm.get('maritalStatus').value != 'अन्य'" [class.col-md-6]="MembershipForm.get('maritalStatus').value != 'विवाहित' && MembershipForm.get('maritalStatus').value != 'अन्य'"
                *ngIf="MembershipForm.get('gender').value == 'स्त्री' || MembershipForm.get('gender').value == 'पुरुष'">
                <div class="form-group">
                    <label class="label mandatory">विवाहित</label>
                    <select class="form-control" formControlName="maritalStatus">
                    <option value="विवाहित">विवाहित</option>
                    <option value="अविवाहित">अविवाहित</option>
                    <option value="अन्य">अन्य</option>
                </select>
                    <div class="error-block" *ngIf="MembershipForm.get('maritalStatus').touched && MembershipForm.get('maritalStatus').errors?.required">यह जानकारी अनिवार्य है।</div>
                </div>
            </div>
            <div class="col-12 col-lg-3 col-md-3" *ngIf="MembershipForm.get('maritalStatus').value == 'विवाहित' || MembershipForm.get('maritalStatus').value == 'अन्य'">
                <div class="form-group">
                    <label class="label mandatory">बच्चों की संख्या</label>
                    <input type="text" class="form-control" formControlName="childrenCount" minlength="1" pattern="[0-9]" maxlength="1" />
                    <div class="error-block" *ngIf="MembershipForm.get('childrenCount').touched && MembershipForm.get('childrenCount').errors?.required">यह जानकारी अनिवार्य है।</div>
                    <div class="error-block" *ngIf="MembershipForm.get('childrenCount').dirty && MembershipForm.get('childrenCount').errors?.pattern">केवल अंकों में भरें।</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">आधार सं०</label>
                    <div style="position: relative;">
                        <input type="text" class="form-control" formControlName="aadhar" minlength="12" pattern="\d*" maxlength="12" (input)="checkAadhar()" />
                        <i class="fas fa-check-circle" style="position: absolute; top: 10px; color:green; right: 10px" *ngIf="aadharExists == 1"></i>
                        <i class="fas fa-times-circle" style="position: absolute; top: 10px; color:red; right: 10px" *ngIf="aadharExists == 2"></i>
                    </div>
                    <div class="error-block" *ngIf="MembershipForm.get('aadhar').touched && MembershipForm.get('aadhar').errors?.required">यह जानकारी अनिवार्य है।</div>
                    <div class="error-block" *ngIf="MembershipForm.get('aadhar').dirty && MembershipForm.get('aadhar').errors?.pattern">केवल अंकों में भरें।</div>
                    <div class="error-block" *ngIf="MembershipForm.get('aadhar').touched && MembershipForm.get('aadhar').errors?.minlength">12 अंकों का आधार नं० भरें।</div>
                    <div class="error-block" *ngIf="aadharExists == 2">यह आधार नं० पंजीकृत है।</div>
                </div>
            </div>
        </div>
        <h3 class="subTitle">सम्पर्क जानकारी</h3>
        <div class="row">
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">मोबाइल नं०</label>
                    <input type="text" class="form-control" formControlName="mobile" pattern="\d*" maxlength="10" />
                    <div class="error-block" *ngIf="MembershipForm.get('mobile').touched && MembershipForm.get('mobile').errors?.required">यह जानकारी अनिवार्य है।</div>
                    <div class="error-block" *ngIf="MembershipForm.get('mobile').dirty && MembershipForm.get('mobile').errors?.pattern">केवल अंकों में भरें।</div>
                    <div class="error-block" *ngIf="MembershipForm.get('mobile').touched && MembershipForm.get('mobile').errors?.minlength">10 अंकों का मोबाइल नं० भरें।</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">ईमेल</label>
                    <input type="text" class="form-control" formControlName="email" email maxlength="100" />
                    <div class="error-block" *ngIf="MembershipForm.get('email').touched && MembershipForm.get('email').errors?.required">यह जानकारी अनिवार्य है।</div>
                    <div class="error-block" *ngIf="MembershipForm.get('email').dirty && MembershipForm.get('email').errors?.email">उचित ईमेल भरें।</div>
                </div>
            </div>
            <div class="col-12 col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="label">पता</label>
                    <input type="text" class="form-control" formControlName="address" maxlength="100" />
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">नगर / शहर / क़स्बा / ग्राम</label>
                    <input type="text" class="form-control" formControlName="city" maxlength="50" />
                    <div class="error-block" *ngIf="MembershipForm.get('city').touched && MembershipForm.get('city').errors?.required">यह जानकारी अनिवार्य है।</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">जनपद / ज़िला</label>
                    <input type="text" class="form-control" formControlName="district" maxlength="50" />
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">राज्य</label>
                    <input type="text" class="form-control" formControlName="state" maxlength="50" />
                    <div class="error-block" *ngIf="MembershipForm.get('state').touched && MembershipForm.get('state').errors?.required">यह जानकारी अनिवार्य है।</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">पिन कोड</label>
                    <input type="text" class="form-control" formControlName="pin" maxlength="6" />
                </div>
            </div>
        </div>
        <h3 class="subTitle">शैक्षिक व व्यवसायिक जानकारी</h3>
        <div class="row">
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">शैक्षिक स्तर</label>
                    <select class="form-control" formControlName="qualificationLevel">
                        <option value="दसवीं से कम">दसवीं से कम</option>
                        <option value="दसवीं">दसवीं</option>
                        <option value="बारहवीं">बारहवीं</option>
                        <option value="ग्रैजूएट">ग्रैजूएट</option>
                        <option value="पोस्ट-ग्रैजूएट">पोस्ट-ग्रैजूएट</option>
                        <option value="प्रोफ़ैशनल">प्रोफ़ैशनल</option>
                        <option value="डॉक्टरेट">डॉक्टरेट</option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">शैक्षिक विवरण</label>
                    <input type="text" class="form-control" formControlName="qualification" maxlength="100" />
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">व्यवसाय</label>
                    <select class="form-control" formControlName="occupation">
                        <option value="नौकरी">नौकरी</option>
                        <option value="व्यवसाय – प्रोफ़ैशनल">व्यवसाय – प्रोफ़ैशनल</option>
                        <option value="व्यवसाय – नॉन-प्रोफ़ैशनल">व्यवसाय – नॉन-प्रोफ़ैशनल</option>
                        <option value="व्यापार">व्यापार</option>
                        <option value="सेवानिवतृत्त">सेवानिवतृत्त</option>
                        <option value="ग्रहिणी">ग्रहिणी</option>
                        <option value="अन्य">अन्य</option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">कम्पनी</label>
                    <input type="text" class="form-control" formControlName="company" maxlength="100" />
                </div>
            </div>
        </div>
        <h3 class="subTitle">पारिवारिक जानकारी</h3>
        <div class="row">
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">माता का नाम</label>
                    <input type="text" class="form-control" formControlName="motherName" maxlength="100" />
                    <div class="error-block" *ngIf="MembershipForm.get('motherName').touched && MembershipForm.get('motherName').errors?.required">यह जानकारी अनिवार्य है।</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">माता का गौत्र</label>
                    <input type="text" class="form-control" formControlName="motherGotra" maxlength="100" />
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">पिता का नाम</label>
                    <input type="text" class="form-control" formControlName="fatherName" maxlength="100" />
                    <div class="error-block" *ngIf="MembershipForm.get('fatherName').touched && MembershipForm.get('fatherName').errors?.required">यह जानकारी अनिवार्य है।</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">पिता का गौत्र</label>
                    <input type="text" class="form-control" formControlName="fatherGotra" maxlength="100" />
                </div>
            </div>
            <div *ngIf="MembershipForm.get('maritalStatus').value == 'विवाहित' && MembershipForm.get('gender').value" class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label"><span *ngIf="MembershipForm.get('gender').value == 'स्त्री'">पति</span><span *ngIf="MembershipForm.get('gender').value == 'पुरुष'">पत्नी</span> का नाम</label>
                    <input type="text" class="form-control" formControlName="spouseName" maxlength="100" />
                </div>
            </div>
            <div *ngIf="MembershipForm.get('maritalStatus').value == 'विवाहित' && MembershipForm.get('gender').value" class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label"><span *ngIf="MembershipForm.get('gender').value == 'स्त्री'">पति</span><span *ngIf="MembershipForm.get('gender').value == 'पुरुष'">पत्नी</span> का गौत्र</label>
                    <input type="text" class="form-control" formControlName="spouseGotra" maxlength="100" />
                </div>
            </div>
        </div>
        <h3 class="subTitle">Uploads</h3>
        <div class="row">
            <div class="col-12 col-lg-4 col-md-4">
                <div class="inputBox">
                    <div>Upload your Photo</div>
                    <div class="btn-upload">
                        <label *ngIf="!showImgCropper[0]" class="btn btn-sm btn-warning">
                        <input type="file" class="inputfile" (change)="fileChangeEventLive($event)" accept="image/*" /> Choose Image
                    </label>
                        <label *ngIf="showImgCropper[0]" class="btn btn-sm btn-warning">
                        <input type="file" class="inputfile" (change)="fileChangeEventLive($event)" accept="image/*" /> Change Image
                    </label>
                    </div>
                    <image-cropper *ngIf="showImgCropper[0]" [imageChangedEvent]="imageChangedEventLive" [maintainAspectRatio]="1/1" [resizeToWidth]="500" format="jpeg" [imageQuality]="90" (imageCropped)="imageCroppedLive($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"></image-cropper>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-md-4">
                <div class="inputBox">
                    <div>Upload Aadhar Card - Front Side</div>
                    <div class="btn-upload">
                        <label *ngIf="!showImgCropper[1]" class="btn btn-sm btn-warning">
                        <input type="file" class="inputfile" (change)="fileChangeEventAF($event)" accept="image/*" /> Choose Image
                    </label>
                        <label *ngIf="showImgCropper[1]" class="btn btn-sm btn-warning">
                        <input type="file" class="inputfile" (change)="fileChangeEventAF($event)" accept="image/*" /> Change Image
                    </label>
                    </div>
                    <image-cropper *ngIf="showImgCropper[1]" [imageChangedEvent]="imageChangedEventAF" [maintainAspectRatio]="false" [resizeToWidth]="500" format="jpeg" [imageQuality]="90" (imageCropped)="imageCroppedAF($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"></image-cropper>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-md-4">
                <div class="inputBox">
                    <div>Upload Aadhar Card - Back Side</div>
                    <div class="btn-upload">
                        <label *ngIf="!showImgCropper[2]" class="btn btn-sm btn-warning">
                        <input type="file" class="inputfile" (change)="fileChangeEventAB($event)" accept="image/*" /> Choose Image
                    </label>
                        <label *ngIf="showImgCropper[2]" class="btn btn-sm btn-warning">
                        <input type="file" class="inputfile" (change)="fileChangeEventAB($event)" accept="image/*" /> Change Image
                    </label>
                    </div>
                    <image-cropper *ngIf="showImgCropper[2]" [imageChangedEvent]="imageChangedEventAB" [maintainAspectRatio]="false" [resizeToWidth]="500" format="jpeg" [imageQuality]="90" (imageCropped)="imageCroppedAB($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"></image-cropper>
                </div>
            </div>
        </div>
        <div *ngIf="MembershipForm.get('maritalStatus').value == 'विवाहित' || MembershipForm.get('maritalStatus').value == 'अन्य'">
            <h3 class="subTitle">संतान का विवरण</h3>
            <div formArrayName="children" class="childrenSection" *ngFor="let child of MembershipForm.get('children').controls; let i = index">
                <div [formGroupName]="i">
                    <h4>संतान {{i+1}}</h4>
                    <div class="row">
                        <div class="col-12 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="label mandatory">नाम</label>
                                <input type="text" class="form-control" formControlName="name" maxlength="100" />
                                <div class="error-block" *ngIf="child.controls.name.touched && child.controls.name.errors?.required">यह जानकारी अनिवार्य है।</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="label">उपनाम</label>
                                <input type="text" class="form-control" formControlName="surname" maxlength="100" placeholder="विश्वकर्मा, पाँचाल, जाँगिड आदि" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="label mandatory">लिंग</label>
                                <select class="form-control" formControlName="gender">
                            <option value="स्त्री">स्त्री</option>
                            <option value="पुरुष">पुरुष</option>
                            <option value="अन्य">अन्य</option>
                        </select>
                                <div class="error-block" *ngIf="child.controls.gender.touched && child.controls.gender.errors?.required">यह जानकारी अनिवार्य है।</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="label">जन्मतिथि</label>
                                <input type="date" class="form-control" formControlName="dob" placeholder="YYYY-MM-DD" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="label">मोबाइल नं०</label>
                                <input type="text" class="form-control" formControlName="mobile" pattern="\d*" minlength="10" maxlength="10" />
                                <div class="error-block" *ngIf="child.controls.mobile.dirty && child.controls.mobile.errors?.pattern">केवल अंकों में भरें।</div>
                                <div class="error-block" *ngIf="child.controls.mobile.touched && child.controls.mobile.errors?.minlength">10 अंकों का मोबाइल नं० भरें।</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="label">ईमेल</label>
                                <input type="text" class="form-control" formControlName="email" email maxlength="100" />
                                <div class="error-block" *ngIf="child.controls.email.dirty && child.controls.email.errors?.email">उचित ईमेल भरें।</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="label">शैक्षिक स्तर</label>
                                <select class="form-control" formControlName="qualification">
                                    <option value="दसवीं से कम">दसवीं से कम</option>
                                    <option value="दसवीं">दसवीं</option>
                                    <option value="बारहवीं">बारहवीं</option>
                                    <option value="ग्रैजूएट">ग्रैजूएट</option>
                                    <option value="पोस्ट-ग्रैजूएट">पोस्ट-ग्रैजूएट</option>
                                    <option value="प्रोफ़ैशनल">प्रोफ़ैशनल</option>
                                    <option value="डॉक्टरेट">डॉक्टरेट</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="label">व्यवसाय</label>
                                <select class="form-control" formControlName="occupation">
                                    <option value="नौकरी">नौकरी</option>
                                    <option value="व्यवसाय – प्रोफ़ैशनल">व्यवसाय – प्रोफ़ैशनल</option>
                                    <option value="व्यवसाय – नॉन-प्रोफ़ैशनल">व्यवसाय – नॉन-प्रोफ़ैशनल</option>
                                    <option value="व्यापार">व्यापार</option>
                                    <option value="सेवानिवतृत्त">सेवानिवतृत्त</option>
                                    <option value="ग्रहिणी">ग्रहिणी</option>
                                    <option value="अन्य">अन्य</option>
                        </select>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-md-6" *ngIf="child.controls.gender.value == 'स्त्री' || child.controls.gender.value == 'पुरुष'">
                            <div class="form-group">
                                <label class="label">विवाहित</label>
                                <select class="form-control" formControlName="maritalStatus">
                            <option value="विवाहित">विवाहित</option>
                            <option value="अविवाहित">अविवाहित</option>
                            <option value="अन्य">अन्य</option>
                        </select>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-md-6" *ngIf="child.controls.maritalStatus.value == 'विवाहित' || child.controls.maritalStatus.value == 'अन्य'">
                            <div class="form-group">
                                <label class="label">बच्चों की संख्या</label>
                                <input type="number" class="form-control" formControlName="children" />
                                <span *ngIf="child.controls.children.errors?.max">Check Max</span>
                            </div>
                        </div>
                    </div>
                </div>
                <i class="fas fa-times removeBtn" (click)="removeChildren(i)"></i>
            </div>
            <div class="btn-group" *ngIf="MembershipForm.get('childrenCount').value >= MembershipForm.controls.children?.length + 1" style="text-align: right;">
                <button (click)="addChildren()" class="btn btn-outline-primary">संतान जोड़ें</button>
            </div>
        </div>
        <div class="btn-group">
            <button [disabled]="MembershipForm.pristine || MembershipForm.invalid" class="btn btn-primary" type="submit" (click)="uploadPhoto()">सबमिट करें</button>
        </div>
    </form>
</div>