import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ShareService } from '../common/share.service';
import { DataService } from '../common/data.service';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'app-business-detail',
  templateUrl: './business-detail.component.html',
  styleUrls: ['./business-detail.component.css']
})
export class BusinessDetailComponent implements OnInit {

slug: string;
businessDetails: any;

  constructor(public share: ShareService,
    private ds: DataService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.aRoute.params.subscribe(params => {
      this.slug = (params["slug"]);
      this.getBusinessDetails();
    });
  }

  getBusinessDetails() {
    this.spinner.show()
    this.ds.createService(environment.apiURL + "GenericSqlDB/ExecuteProcedureMultiPostJSON",
      {
        ProcedureName: 'GetBusinessDetails', SqlParamsArr: [
          { ParamName: '@Slug', ParamValue: this.slug }
        ]
      }).subscribe
      (data => {
        if (data && data.length > 0) {
          this.businessDetails = data;
        }
        else {
          this.router.navigate(['not-found'])
        }
        this.spinner.hide()
      });
  }

}
