<app-header></app-header>
<img src="assets/images/HPBM-Shree-Vishwakarma-Home.jpg" style="width:100%" />
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle">हरियाणा पाँचाल ब्राह्मण महासभा की वेब साईट पर आप सब का स्वागत है।</h1>
    <p>
        हमारा उद्देश्य, पाँचाल समाज के बीच और पाँचाल समाज के लोगों में एकता और एकजुटता को बढ़ावा देना है और साथ साथ दिल्ली एन.सी.आर. के शहरों में रहने वाले व्यस्त लोगों को एक साथ, एक मंच पर लाकर एक साथ आगे बढ़ने का है। हम पाँचाल समाज को एक स्वस्थ, सशक्त एवम्
        समृद्ध समाज बनाने के उद्देश्य को लेकर आगे बढ़ रहे हैं।
    </p>
    <div class="inMatterImg">
        <video class="web" controls preload="metadata" poster="assets/images/HPBM-Shree-Vishwakarma-Video-by-Rajshri-Soul.jpg">
            <source src="assets/images/HPBM-Shree-Vishwakarma-Video-by-Rajshri-Soul-Web.webm" type="video/webm" />
            <source src="assets/images/HPBM-Shree-Vishwakarma-Video-by-Rajshri-Soul-Web.mp4" type="video/mp4" />
        </video>
        <video class="mobile" controls preload="metadata" poster="assets/images/HPBM-Shree-Vishwakarma-Video-by-Rajshri-Soul.jpg">
            <source src="assets/images/HPBM-Shree-Vishwakarma-Video-by-Rajshri-Soul-Mobile.webm" type="video/webm" />
            <source src="assets/images/HPBM-Shree-Vishwakarma-Video-by-Rajshri-Soul-Mobile.mp4" type="video/mp4" />
        </video>
    </div>
    <p>
        पाँचाल समाज की प्रगति और उनके सामाजिक, शैक्षणिक, सांस्कृतिक, आध्यात्मिक, बौद्धिक और आर्थिक विकास के लिए सदा हमारा प्रयास रहता है। और हम इसके लिए कार्यरत भी हैं। हम पाँचाल समाज के बीच सामाजिक जागरूकता लाने के साथ-साथ समाज को प्रगतिशील दृष्टिकोण प्रदान
        करने का काम भी करते हैं। राष्ट्रीय, सांस्कृतिक, सामाजिक कार्यों और त्यौहारों के उत्सव को बढ़ावा देने के लिए एक संगठित व्यवस्था का निर्माण करते हैं।</p>

    <p>पाँचाल समाज के हर व्यक्ति के अन्दर शक्ति तो बहुत है, लेकिन वह व्यक्ति जीवन की दिनचर्या के बोझ तले दबकर समाज के प्रति अपना कर्तव्य भूल सा गये हैं। ऐसे लोगों को उनके दायित्व और कर्तव्यों को स्मरण कराने का हमारा सतत प्रयास जारी है।
    </p>
</div>
<app-footer></app-footer>