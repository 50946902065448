<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi' && gallery && gallery?.length > 0">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;गैलरी&nbsp;<i class="fas fa-angle-right"></i>&nbsp;{{gallery[0].Title}}
    </div>
</div>
<div class="container" *ngIf="gallery && gallery?.length > 0">
    <ng-container *ngFor="let item of gallery; let i = index;">
        <h1 class="pageTitle hindi">Gallery - {{item.Title}}</h1>
        <div class="hindi">
            <div style="margin-bottom: 10px;">Event Date: {{item.EventDate}}</div>
            <p [innerHTML]="item.Caption"></p>
        </div>
        <div class="galleryGroup">
            <div class="galleryBlock" (click)="openmodal(modal)" *ngFor="let photo of item.Images; let j = index">
                <div class="galleryThumb" [style.background]="'url(' + share.home.apiURL + 'Uploads/Gallery/' + photo.Thumbnail + ')'">
                </div>
                <ng-template #modal let-d="close">
                    <div class="modal-header" style="padding: 20px 10px !important; padding-bottom: 10px !important">
                        <h5 style="margin-bottom: 0px !important; width: 100%;">
                            <span></span>
                            <i (click)="d('Cross click')" class="fas fa-times" style="float: right; font-size: 0.8rem !important;"></i>
                        </h5>
                    </div>
                    <div class="modal-body">
                        <img style="width: 100%;" [src]="share.home.apiURL + 'Uploads/Gallery/' + photo.Path" />
                    </div>
                    <div class="modal-footer aboutModal likeModal">
                    </div>
                </ng-template>
            </div>
        </div>
    </ng-container>
</div>
<app-footer></app-footer>