<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;Member Registration 2022
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">Member Registration 2022</h1>

    <form [formGroup]="MembershipForm">
        <h3 class="subTitle">Personal Details</h3>
        <div class="row">
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">Salutation</label>
                    <select class="form-control" formControlName="salutation">
                    <option value="Mr.">Mr.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Ms.">Ms.</option>
                    <option value="Prof.">Prof.</option>
                    <option value="Dr.">Dr.</option>
                </select>
                    <div class="error-block" *ngIf="MembershipForm.get('salutation').touched && MembershipForm.get('salutation').errors?.required">Salutation is required.</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">Name</label>
                    <input type="text" class="form-control" formControlName="name" maxlength="100" />
                    <div class="error-block" *ngIf="MembershipForm.get('name').touched && MembershipForm.get('name').errors?.required">Name is required.</div>
                </div>
            </div>

            <div class="col-12 col-lg-3 col-md-3">
                <div class="form-group">
                    <label class="label mandatory">Date of Birth</label>
                    <input type="date" class="form-control" formControlName="dob" placeholder="YYYY-MM-DD" />
                    <div class="error-block" *ngIf="MembershipForm.get('dob').touched && MembershipForm.get('dob').errors?.required">Date of Birth is required.</div>
                </div>
            </div>
            <div class="col-12 col-lg-3 col-md-3">
                <div class="form-group">
                    <label class="label mandatory">Aadhar No.</label>
                    <input type="text" class="form-control" formControlName="aadhar" minlength="12" pattern="\d*" maxlength="12" placeholder="e.g. 123456789123" />
                    <div class="error-block" *ngIf="MembershipForm.get('aadhar').touched && MembershipForm.get('aadhar').errors?.required">Aadhar No. is required.</div>
                    <div class="error-block" *ngIf="MembershipForm.get('aadhar').dirty && MembershipForm.get('aadhar').errors?.pattern">Enter in Digits only.</div>
                    <div class="error-block" *ngIf="MembershipForm.get('aadhar').touched && MembershipForm.get('aadhar').errors?.minlength">Enter 12-digit Aadhar No.</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">Father's Name</label>
                    <input type="text" class="form-control" formControlName="fatherName" maxlength="100" />
                    <div class="error-block" *ngIf="MembershipForm.get('fatherName').touched && MembershipForm.get('fatherName').errors?.required">Father's Name is required.</div>
                </div>
            </div>
        </div>
        <h3 class="subTitle">Contact Details</h3>
        <div class="row">
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">Mobile No.</label>
                    <input type="text" class="form-control" formControlName="mobile" pattern="\d*" maxlength="10" />
                    <div class="error-block" *ngIf="MembershipForm.get('mobile').touched && MembershipForm.get('mobile').errors?.required">Mobile No. is required.</div>
                    <div class="error-block" *ngIf="MembershipForm.get('mobile').dirty && MembershipForm.get('mobile').errors?.pattern">Enter in Digits only.</div>
                    <div class="error-block" *ngIf="MembershipForm.get('mobile').touched && MembershipForm.get('mobile').errors?.minlength">Enter 10-digit Mobile No.</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">Email ID</label>
                    <input type="text" class="form-control" formControlName="email" email maxlength="100" />
                    <div class="error-block" *ngIf="MembershipForm.get('email').dirty && MembershipForm.get('email').errors?.email">Enter valid Email Address.</div>
                </div>
            </div>
            <div class="col-12 col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="label">Address</label>
                    <input type="text" class="form-control" formControlName="address" maxlength="100" />
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">City</label>
                    <input type="text" class="form-control" formControlName="city" maxlength="50" />
                    <div class="error-block" *ngIf="MembershipForm.get('city').touched && MembershipForm.get('city').errors?.required">City is required.</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">District</label>
                    <input type="text" class="form-control" formControlName="district" maxlength="50" />
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label mandatory">State</label>
                    <input type="text" class="form-control" formControlName="state" maxlength="50" />
                    <div class="error-block" *ngIf="MembershipForm.get('state').touched && MembershipForm.get('state').errors?.required">State is required.</div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="label">PIN Code</label>
                    <input type="text" class="form-control" formControlName="pin" maxlength="6" />
                </div>
            </div>
        </div>
        <h3 class="subTitle">Uploads</h3>
        <div class="row">
            <div class="col-12 col-lg-4 col-md-4">
                <div class="inputBox">
                    <div class="mandatory">Upload Aadhar Card - Front Side</div>
                    <div class="btn-upload">
                        <label *ngIf="!showImgCropper[0]" class="btn btn-sm btn-warning">
                        <input type="file" class="inputfile" (change)="fileChangeEventAF($event)" accept="image/*" /> Choose Image
                    </label>
                        <label *ngIf="showImgCropper[0]" class="btn btn-sm btn-warning">
                        <input type="file" class="inputfile" (change)="fileChangeEventAF($event)" accept="image/*" /> Change Image
                    </label>
                    </div>
                    <image-cropper *ngIf="showImgCropper[0]" [imageChangedEvent]="imageChangedEventAF" [maintainAspectRatio]="false" [resizeToWidth]="500" format="jpeg" [imageQuality]="90" (imageCropped)="imageCroppedAF($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"></image-cropper>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-md-4">
                <div class="inputBox">
                    <div class="mandatory">Upload Aadhar Card - Back Side</div>
                    <div class="btn-upload">
                        <label *ngIf="!showImgCropper[1]" class="btn btn-sm btn-warning">
                        <input type="file" class="inputfile" (change)="fileChangeEventAB($event)" accept="image/*" /> Choose Image
                    </label>
                        <label *ngIf="showImgCropper[1]" class="btn btn-sm btn-warning">
                        <input type="file" class="inputfile" (change)="fileChangeEventAB($event)" accept="image/*" /> Change Image
                    </label>
                    </div>
                    <image-cropper *ngIf="showImgCropper[1]" [imageChangedEvent]="imageChangedEventAB" [maintainAspectRatio]="false" [resizeToWidth]="500" format="jpeg" [imageQuality]="90" (imageCropped)="imageCroppedAB($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"></image-cropper>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-md-4">
                <div class="inputBox">
                    <div class="mandatory">Upload your Photo</div>
                    <div class="btn-upload">
                        <label *ngIf="!showImgCropper[2]" class="btn btn-sm btn-warning">
                        <input type="file" class="inputfile" (change)="fileChangeEventLive($event)" accept="image/*" /> Choose Image
                    </label>
                        <label *ngIf="showImgCropper[2]" class="btn btn-sm btn-warning">
                        <input type="file" class="inputfile" (change)="fileChangeEventLive($event)" accept="image/*" /> Change Image
                    </label>
                    </div>
                    <image-cropper *ngIf="showImgCropper[2]" [imageChangedEvent]="imageChangedEventLive" [maintainAspectRatio]="false" [resizeToWidth]="500" format="jpeg" [imageQuality]="90" (imageCropped)="imageCroppedLive($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"></image-cropper>
                </div>
            </div>
        </div>
        <div style="text-align: center;">
            <button [disabled]="MembershipForm.pristine || MembershipForm.invalid" class="btn btn-primary" type="submit" (click)="submitForm(content)">Submit</button>
        </div>
    </form>
</div>