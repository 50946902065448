import { Component, OnInit } from '@angular/core';
import { ShareService } from '../common/share.service';
import { DataService } from '../common/data.service';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.css']
})
export class AlbumsComponent implements OnInit {

  album = [];
  character: number;

  constructor(public share: ShareService, private ds: DataService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    if (window.screen.width > 1024) {
      this.character = 110
    }
    else {
      this.character = 60
    }
    this.getAlbums()
  }

  getAlbums() {
    if (this.share.gallery.albums && this.share.gallery.albums.length > 0) {
      this.album = this.share.gallery.albums;
    }
    else {
      this.spinner.show()
      this.ds.createService(environment.apiURL + "GenericSqlDB/ExecuteProcedureMultiPostJSON",
        {
          ProcedureName: 'GetAlbums', SqlParamsArr: [
            { ParamName: '@UserID', ParamValue: 0 }]
        }).subscribe
        (data => {
          if (data && data.length > 0) {
            this.album = data;
            for (let i = 0; i < data.length; i++) {
              this.album[i].Caption = (this.album[i].Caption.slice(0, this.character)) + '...';
            }
            this.share.gallery.albums = this.album;
          }
          else {
          }
          this.spinner.hide()
        });
    }
  }
}