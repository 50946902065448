<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;विश्वकर्मा&nbsp;<i class="fas fa-angle-right"></i>&nbsp;कई स्वरूप
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">भगवान श्री विश्वकर्मा - कई स्वरूप</h1>

    <div class="row">
        <div class="col-12 col-lg-5 col-md-5">
            <img style="width: 100%; margin-bottom: 30px;" src="assets/images/HPBM-Shree-Vishwakarma-Kai-Swaroop.jpeg" />
        </div>
        <div class="col-12 col-lg-7 col-md-7">
            <p>
                हमारे धर्मशास्त्रों और ग्रन्थों में विश्वकर्मा के पाँच स्वरुपों और अवतारों का वर्णन प्राप्त होता है।<br/>• विराट विश्वकर्मा - सृष्टि के रचेता<br/>• धर्मवंशी विश्वकर्मा - महान शिल्प विज्ञान विधाता प्रभात पुत्र<br/>• अंगिरावंशी विश्वकर्मा
                - आदि विज्ञान विधाता वसु पुत्र<br/>• सुधन्वा विश्वकर्म - महान शिल्पाचार्य विज्ञान जन्मदाता ऋषि अथवी के पौत्र<br/>• भृंगुवंशी विश्वकर्मा - उत्कृष्ट शिल्प विज्ञानाचार्य (शुक्राचार्य के पौत्र)
            </p>
            <p>
                देवगुरु बृहस्पति की भगिनी भुवना के पुत्र भौवन विश्वकर्मा की वंश परम्परा अत्यन्त वृहद् है। महाभारत के खिल भाग सहित सभी पुराणकार प्रभात पुत्र विश्वकर्मा को आदि विश्वकर्मा मानते हैं। स्कन्दपुराण प्रभात खण्ड के निम्न श्लोक की भाँति पाठ भेद से सभी पुराणों
                में यह श्लोक मिलता है-
            </p>
            <p class="shlok">
                बृहस्पते भगिनी भुवना ब्रह्मवादिनी।<br/> प्रभासस्य तस्य भार्या बसूनामष्टमस्य च।<br/> विश्वकर्मा सुतस्तस्यशिल्पकर्ता प्रजापतिः॥16॥
            </p>
            <p class="shlokSource">स्कन्दपुराण प्रभास खण्ड अध्याय 21 श्लोक 16 </p>
            <p>
                महर्षि अंगिरा के ज्येष्ठ पुत्र बृहस्पति की बहन भुवना, जो ब्रह्मविद्या जानने वाली थी, वह अष्टम् वसु महर्षि प्रभास की पत्नी बनी और उससे सम्पूर्ण शिल्प विद्या के ज्ञाता प्रजापति विश्वकर्मा का जन्म हुआ। पुराणों में कहीं-कहीं बृहस्पति की बहन का नाम योगसिद्धा,
                वरस्त्री भी लिखा है।
            </p>
        </div>
    </div>
    <p>
        शिल्प शास्त्र का कर्ता वह ईश विश्वकर्मा देवताओं का आचार्य है, सम्पूर्ण सिद्धियों का जनक है, वह प्रभास ऋषि का पुत्र है और महर्षि अंगिरा के ज्येष्ठ पुत्र का भाणजा है। अर्थात अंगिरा का दौहितृ (दोहिता) है। जिस तरह भारत मे विश्वकर्मा को शिल्पशास्त्र का अविष्कार
        करने वाला देवता माना जाता हे और सभी कारीगर उनकी पूजा करते है।
    </p>
    <p>
        आर्दव वसु प्रभास नामक को अंगिरा की पुत्री बृहस्पति जी की बहिन योगसिद्ध विवाही गई और अष्टम वसु प्रभास से जो पुत्र उत्पन्न हुआ उसका नाम विश्वकर्मा हुआ जो शिल्प प्रजापति कहलाया इसका वर्णन वायुपुराण अ.22 उत्तर भाग में दिया हैः
    </p>
    <p class="shlok">
        बृहस्पतेस्तु भगिनो वरस्त्री ब्रह्मचारिणी। योगसिद्धा जगत्कृत्स्नमसक्ता चरते सदा।।<br/>प्रभासस्य तु सा भार्या वसु नामष्ट मस्य तु। विश्वकर्मा सुत स्तस्यां जातः शिल्प प्रजापतिः।।
    </p>
    <p class="shlokSource">वायुपुराण अध्याय 22 उत्तर भाग</p>
    <p class="shlok">
        विश्वकर्मा प्रभासस्य पुत्रः शिल्प प्रजापतिः। प्रसाद भवनोद्यान प्रतिमा भूषणादिषु। तडागा राम कूप्रेषु स्मृतः सोमSवर्धकी।।</p>
    <p class="shlokSource">मत्स्यपुराण अध्याय 5</p>
    <p>अर्थः प्रभास का पुत्र शिल्प प्रजापति विश्वकर्मा देव, मन्दिर, भवन, देवमूर्ति, आभूषण, तालाब, बावडी और कुएं निर्माण करने वाले देवाचार्य थे।</p>
    <p class="shlok">
        विश्वकर्मा प्रभासस्य धर्मस्थः पातु स ततः।।
    </p>
    <p class="shlokSource">आदित्य पुराण</p>
</div>
<div class="container" *ngIf="share.home.language == 'English'">
    <h1 class="pageTitle">Shree Vishwakarma - Ek Rishi</h1>
</div>
<app-footer></app-footer>