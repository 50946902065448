<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;ID कार्ड डाउनलोड
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">ID कार्ड डाउनलोड </h1>
    <form>
        <div class="row">
            <div class="col-12 col-lg-4 col-md-4 offset-lg-4 offset-md-4">
                <div class="form-group">
                    <label for="memberID" class="label mandatory">मेम्बर ID</label>
                    <input id="memberID" type="text" class="form-control" name="memberID" [(ngModel)]="memberID" />
                </div>
                <div class="form-group" style="text-align:center">
                    <button class="btn btn-primary">डाउनलोड करें</button>
                </div>
            </div>
        </div>
    </form>
</div>