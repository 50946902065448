<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;विश्वकर्मा&nbsp;<i class="fas fa-angle-right"></i>&nbsp;एक उपाधि
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">भगवान श्री विश्वकर्मा - एक उपाधि</h1>
    <p>जैसा कि पहले ही बताया जा चुका है कि श्री विश्वकर्मा जी के विभिन्न स्वरुप हैं और आगे चलकर श्री विश्वकर्मा के गुणों को धारण करने वाले हृष्ट पुरुष को विश्वकर्मा की उपाधि से अलंकृत किया जाने लगा। शास्त्र में भी लिखा हैः</p>
    <div class="inMatterImg">
        <img alt="Shree Vishwakarma Parichay Shlok" src="assets/images/HPBM-Shree-Vishwakarma-Ek-Upadhi.jpg" />
    </div>
    <p class="shlok">
        स्थपति स्थापनाई: स्यात् सर्वशास्त्र विशारद :।<br/>न हिनांगो अतिरिक्तांगो धार्मिकस्तु दयापर:।1।<br/>अमात्सर्यो असूयश्चातन्द्रियतस्त्वभिजातवान्।<br/> गणितज्ञ: पुराणज्ञ सत्यवादी जितेन्द्रिय:।2।<br/> गुरुभक्ता सदाहष्टा: स्थपत्याज्ञानुगा: सदा।
        <br/> तेषाम्व स्तपत्याख्यो विश्वकर्मेति सस्मृत:।3।
    </p>
    <p>
        अर्थ :- जो शिल्पी निर्माण कला में सिद्धहस्त सम्पूर्ण शास्त्रों का पूर्ण पण्डित हो, जिसके शरीर का कोई अवयव न अधिक हो न कम हो, दयालु, धर्मात्मा तथा कुलीन हो।।1।।<br/>जो अहंकार करने वाला ईर्ष्यालु और प्रमादी न हो, गणित विद्या का पूर्ण पण्डित हो,
        वेंदों के व्याख्यान रुप ब्राह्मण ग्रन्थों और इतिहास में पारंगत हो।।2।।<br/>सत्यवादी तथा इन्द्रियों को जीतने वाला आज्ञाकारी हो इस प्रकार के गुणों से युक्त रचियता को विश्वकर्मा कहते हैं।।3।।
    </p>
    <p>मयमतम् के कथन से स्पष्ट होता है कि कालान्तर में श्री विश्वकर्मा नामक एक उपाधि भी प्रचलित हो गई थी।</p>
    <p>महाभारत काल तक लगभग दो लाख विश्वकर्माओं के होने का प्रमाण मिलता है।</p>
</div>
<div class="container" *ngIf="share.home.language == 'English'">
    <h1 class="pageTitle">Shree Vishwakarma - Shabd Parichay</h1>
</div>
<app-footer></app-footer>