import { Component, OnInit } from '@angular/core';
import { DataService } from '../common/data.service';
import { ShareService } from '../common/share.service';
import { environment } from '../../environments/environment';
import SwiperCore, { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.css']
})
export class AdsComponent implements OnInit {

  ads: any

  config: SwiperOptions = {
    direction: 'horizontal',
    slidesPerView: 1,
    // slidesPerGroup: 1,
    autoplay: {
      delay: 100,
      disableOnInteraction: true
    },
    speed: 1000,
    loop: true,
    loopFillGroupWithBlank: true,
    spaceBetween: 10,
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };

  constructor(private ds: DataService, public share: ShareService) { }

  ngOnInit(): void {
    this.getAds();
  }

  getAds() {
    if (this.share.home.ads && this.share.home.ads.length > 0) {
      this.ads = this.share.home.ads;
    }
    else {
      this.ds.createService(environment.apiURL + "GenericSqlDB/ExecuteProcedureMultiPostJSON",
        {
          ProcedureName: 'GetAdsThumbnails', SqlParamsArr: [
            { ParamName: '@UserID', ParamValue: 0 }]
        }).subscribe
        (data => {
          if (data && data.length > 0) {
            this.ads = data;
            this.share.home.ads = data
          }
          else {
          }
        });
    }
  }
}
