<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;महासभा&nbsp;<i class="fas fa-angle-right"></i>&nbsp;एक झलक
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">हरियाणा पाँचाल ब्राह्मण महासभा - एक झलक</h1>
    <p>हरियाणा पाँचाल ब्राह्मण महासभा फरीदाबाद की स्थापना 1971 में जवाहर कॉलोनी फरीदाबाद में हुई। तत्पश्चात् 09 अक्टूबर 1979 को महासभा का सोसायटी रेजिस्ट्रेशन अधिनियम 1860 धारा नम्बर 2 के अन्तर्गत पंजीकरण कराया गया। इस महासभा की स्थापना में कुछ गणमान्य महानुभावों
        के साथ-साथ सभी तत्कालीन पाँचाल बन्धुओं का पूर्ण समर्थन रहा।</p>
    <p>महासभा की प्रथम प्रबन्धन समिति के सदस्य इस प्रकार थे।</p>
    <table class="table table-bordered table-condensed table-striped hindi">
        <thead>
            <tr>
                <th>क्र०</th>
                <th>नाम</th>
                <th>पद</th>
                <th>पता</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>श्री राधेश्याम शर्मा</td>
                <td>अध्यक्ष</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
            <tr>
                <td>2</td>
                <td>श्री जगरूप शर्मा</td>
                <td>वरिष्ठ उपाध्यक्ष</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
            <tr>
                <td>3</td>
                <td>श्री आशाराम शर्मा</td>
                <td>उपाध्यक्ष</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
            <tr>
                <td>4</td>
                <td>श्री खजान सिंह</td>
                <td>उपाध्यक्ष</td>
                <td>ग्राम घोड़ी, पलवल</td>
            </tr>
            <tr>
                <td>5</td>
                <td>श्री रायसिंह डांगी</td>
                <td>उपाध्यक्ष</td>
                <td>ग्रा० व पो० गुमाना, रोहतक</td>
            </tr>
            <tr>
                <td>6</td>
                <td>श्री श्याम लाल शर्मा</td>
                <td>उपाध्यक्ष</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
            <tr>
                <td>7</td>
                <td>श्री ओमप्रकाश शर्मा</td>
                <td>महासचिव</td>
                <td>सेक्टर-15, फरीदाबाद</td>
            </tr>
            <tr>
                <td>8</td>
                <td>श्री शिव कुमार डांगी</td>
                <td>उप-सचिव</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
            <tr>
                <td>9</td>
                <td>श्री बालक राम</td>
                <td>उप-सचिव</td>
                <td>ग्राम गोंछी, बल्लबगढ़</td>
            </tr>
            <tr>
                <td>10</td>
                <td>श्री चन्द्रदेव पाँचाल</td>
                <td>कोषाध्यक्ष</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
            <tr>
                <td>11</td>
                <td>पण्डित शिव गणेश शर्मा</td>
                <td>उप-कोषाध्यक्ष</td>
                <td>ग्राम सारन, फरीदाबाद</td>
            </tr>
            <tr>
                <td>12</td>
                <td>पण्डित बृजलाल शास्त्री</td>
                <td>प्रचार व प्रकाशन अध्यक्ष</td>
                <td>शास्त्री कॉलोनी, फरीदाबाद</td>
            </tr>
            <tr>
                <td>13</td>
                <td>श्री रामकिशन पाँचाल</td>
                <td>लेखा-परीक्षक</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
            <tr>
                <td>14</td>
                <td>श्री गिरधारी लाल पाँचाल</td>
                <td>पुस्तिका-व्यस्थापक</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
        </tbody>
    </table>
    <p>महासभा की प्रथम कार्यकारणी समिति के सदस्य इस प्रकार थे।</p>
    <table class="table table-bordered table-condensed table-striped hindi">
        <thead>
            <tr>
                <th>क्र०</th>
                <th>नाम</th>
                <th>पता</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>पण्डित आशाराम शर्मा</td>
                <td>एन आई टी फरीदाबाद</td>
            </tr>
            <tr>
                <td>2</td>
                <td>पण्डित ज्ञानचन्द शर्मा</td>
                <td>ग्राम टीकरी ब्राह्मण, पलवल</td>
            </tr>
            <tr>
                <td>3</td>
                <td>श्री बालेश्वर शर्मा</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
            <tr>
                <td>4</td>
                <td>श्री नानकचन्द शर्मा</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
            <tr>
                <td>5</td>
                <td>श्री ब्रह्मदत्त शर्मा</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
            <tr>
                <td>6</td>
                <td>श्री उमराव सिंह</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
            <tr>
                <td>7</td>
                <td>श्री जिले सिंह आर्य</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
            <tr>
                <td>8</td>
                <td>श्री साहब राम</td>
                <td>जवाहर कॉलोनी, फरीदाबाद</td>
            </tr>
        </tbody>
    </table>
    <p>इस महासभा की स्थापना ने बिखरे हुए व मानसिक रूप से छिन्न-भिन्न पाँचाल ब्राह्मण समाज को ना केवल संगठित व सशक्त किया बल्कि आगे बढ़ने के लिए एक नयी दिशा व उत्साह भी दिया। स्थापन से लेकर आज तक इस महासभा ने अपने उद्देश्यों को सफल करने के लिए पाँचाल ब्राह्मण
        समाज के लिए अनेकों-अनेक प्रयास किए। इन्ही के अथक प्रयासों के परिणामस्वरूप ही आज पाँचाल ब्राह्मण समाज विशेषकर फरीदाबाद पाँचाल ब्राह्मण समाज एक सुदृढ़ व सशक्त वट वृक्ष की भाँति समृद्ध हो विकसित हो रहा है।</p>
    <p>आज पाँचाल ब्राह्मण समाज इस महासभा के संस्थापकों व समर्थकों को शत-शत नमन करता है एवम् इनका सदैव ऋणी रहेगा।</p>
    <h3 class="annexTitle">मिशन</h3>
    <p>हरियाणा पाँचाल ब्राह्मण महासभा फरीदाबाद का लक्ष्य (मिशन) पाँचाल ब्राह्मण समाज में स्व-जातीय एकता लाकर समाज को संगठित, समृद्ध व सम्मानीय बनाना है।</p>
    <h3 class="annexTitle">विज़न</h3>
    <p>हरियाणा पाँचाल ब्राह्मण महासभा फरीदाबाद अपने उपरोक्त लक्ष्य को प्राप्त करने हेतु अपने सम्पूर्ण संसाधनों व कार्यकलापों का इस प्रकार प्रबन्धन व उपयोग करेगी ताकि पाँचाल ब्राह्मण समाज को सामाजिक, आर्थिक, एवम् शैक्षणिक (विशेषरूप से विज्ञान में) उन्नति
        प्राप्त हो सके।</p>
</div>
<div class="container" *ngIf="share.home.language == 'English'">
    <h1 class="pageTitle">Shree Vishwakarma - Shabd Parichay</h1>
</div>
<app-footer></app-footer>