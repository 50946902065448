import { Component, OnInit } from '@angular/core';
import { ShareService } from '../../common/share.service';
import { DataService } from '../../common/data.service';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'app-area-prabhari',
  templateUrl: './area-prabhari.component.html',
  styleUrls: ['./area-prabhari.component.css']
})
export class AreaPrabhariComponent implements OnInit {

  members: any;

  constructor(public share: ShareService,
    private ds: DataService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.members = [
      {Area: 'नंगला एन्क्लेव पार्ट-1', NameHindi: 'श्री वेद प्रकाश पाँचाल'},
      {Area: 'राजीव कॉलोनी', NameHindi: 'श्री हरि राम पाँचाल'},
      {Area: 'जीवन नगर', NameHindi: 'श्री संजय पाँचाल'},
      {Area: 'कपड़ा कॉलोनी/जवाहर कॉलोनी', NameHindi: 'श्री चेतन पाँचाल'},
      {Area: 'डबुआ कॉलोनी', NameHindi: 'श्री एम.एस. डांगी'},
      {Area: 'पर्वतिया कॉलोनी', NameHindi: 'श्री सुनील पाँचाल'},
      {Area: 'संजय कॉलोनी', NameHindi: 'श्री बलवान सिंह'},
      {Area: 'गाजीपुर रोड', NameHindi: 'श्री गोपाल पाँचाल'},
      {Area: '60-फीट रोड जवाहर कॉलोनी', NameHindi: 'श्री परवेश पाँचाल'},
      {Area: 'संजय कॉलोनी सोहना रोड', NameHindi: 'श्री भीम सेन'},
      {Area: 'चाचा चौक', NameHindi: 'श्री सुमृता देवी'},
      {Area: 'संजय कॉलोनी सेक्टर 23', NameHindi: 'श्री ललित वत्स'}
    ]
  }

  getExecutiveCommittee() {
    if (this.share.members.management && this.share.members.management.length > 0) {
      this.members = this.share.members.management;
    }
    else {
      this.spinner.show()
      this.ds.createService(environment.apiURL + "GenericSqlDB/ExecuteProcedureMultiPostJSON",
        {
          ProcedureName: 'GetManagementCommittee', SqlParamsArr: [
            { ParamName: '@UserID', ParamValue: 0 }]
        }).subscribe
        (data => {
          if (data && data.length > 0) {
            this.members = data;
            this.share.members.management = data;
          }
          else {
          }
          this.spinner.hide()
        });
    }
  }
}