import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DataService } from './common/data.service';
import { ShareService } from './common/share.service';

import { SwiperModule } from 'swiper/angular';
import { NgxSpinnerModule } from 'ngx-spinner'
import { ImageCropperModule } from 'ngx-image-cropper';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HistoryComponent } from './vishwakarma/history/history.component';
import { VanshawaliComponent } from './vishwakarma/vanshawali/vanshawali.component';
import { ObjectivesComponent } from './about/objectives/objectives.component';
import { MotivesComponent } from './about/motives/motives.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AlbumsComponent } from './albums/albums.component';
import { AdsComponent } from './ads/ads.component';
import { Albums2016Component } from './albums2016/albums2016.component';
import { Albums2018Component } from './albums2018/albums2018.component';
import { ShabdParichayComponent } from './vishwakarma/shabd-parichay/shabd-parichay.component';
import { EkDevComponent } from './vishwakarma/ek-dev/ek-dev.component';
import { EkRishiComponent } from './vishwakarma/ek-rishi/ek-rishi.component';
import { KaiSwaroopComponent } from './vishwakarma/kai-swaroop/kai-swaroop.component';
import { EkUpadhiComponent } from './vishwakarma/ek-upadhi/ek-upadhi.component';
import { YantroKeDevComponent } from './vishwakarma/yantro-ke-dev/yantro-ke-dev.component';
import { JanmDiwasComponent } from './vishwakarma/janm-diwas/janm-diwas.component';
import { ExecutiveMembersComponent } from './members/executive-members/executive-members.component';
import { BusinessDetailComponent } from './business-detail/business-detail.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { EkJhalakComponent } from './mahasabha/ek-jhalak/ek-jhalak.component';
import { MahasabhaEventsComponent } from './mahasabha/mahasabha-events/mahasabha-events.component';
import { ContactComponent } from './contact/contact.component';
import { ManagementCommitteeComponent } from './members/management-committee/management-committee.component';
import { AreaPrabhariComponent } from './members/area-prabhari/area-prabhari.component';
import { AddMemberComponent } from './add-member/add-member.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddMemberLatestComponent } from './add-member-latest/add-member-latest.component';
import { MembersComponent } from './members/members/members.component';
import { DownloadMemberIdComponent } from './download-member-id/download-member-id.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    HistoryComponent,
    VanshawaliComponent,
    ObjectivesComponent,
    MotivesComponent,
    GalleryComponent,
    AlbumsComponent,
    AdsComponent,
    Albums2016Component,
    Albums2018Component,
    ShabdParichayComponent,
    EkDevComponent,
    EkRishiComponent,
    KaiSwaroopComponent,
    EkUpadhiComponent,
    YantroKeDevComponent,
    JanmDiwasComponent,
    ExecutiveMembersComponent,
    BusinessDetailComponent,
    NotFoundComponent,
    EkJhalakComponent,
    MahasabhaEventsComponent,
    ContactComponent,
    ManagementCommitteeComponent,
    AreaPrabhariComponent,
    AddMemberComponent,
    AddMemberLatestComponent,
    MembersComponent,
    DownloadMemberIdComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    SwiperModule,
    BrowserAnimationsModule,
    ImageCropperModule
  ],
  providers: [DataService, ShareService],
  bootstrap: [AppComponent]
})
export class AppModule { }
