<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg" *ngIf="businessDetails && businessDetails?.length > 0" [style.background]="'url(' + share.home.apiURL + 'Uploads/Companies/' + businessDetails[0].Banner + ')'"></div>
<div class="container" *ngIf="businessDetails && businessDetails?.length > 0">
    <ng-container *ngFor="let item of businessDetails; let i = index">
        <div class="breadCrumb">Haryana Panchal Brahmin Mahasabha > Business > {{item.Category}} > {{item.CompanyName}}<span *ngIf="item.City && item.City?.length > 0">, {{item.City}}</span></div>
        <h1 class="pageTitle">{{item.CompanyName}}</h1>
        <!-- <h3 *ngIf="item.Brief && item.Brief?.length > 0" class="annexTitle">Brief about {{item.CompanyName}}</h3> -->
        <p *ngIf="item.Brief && item.Brief?.length > 0" [innerHTML]="item.Brief"></p>
        <ng-container>
            <address>
                <h3 class="annexTitle">Contact us</h3>
                <span *ngIf="item.ContactName && item.ContactName?.length > 0">{{item.ContactName}}<br/></span>
                <span *ngIf="item.ContactNo1 && item.ContactNo1?.length > 0">{{item.ContactNo1}}<br/></span>
                <span *ngIf="item.ContactNo2 && item.ContactNo2?.length > 0">{{item.ContactNo2}}<br/></span>
                <span *ngIf="item.EmailID && item.EmailID?.length > 0">{{item.EmailID}}<br/></span>
                <span *ngIf="item.Address && item.Address?.length > 0">{{item.Address}}, </span>{{item.City}}
            </address>
        </ng-container>
        <h3 class="annexTitle">What we do</h3>
        <p [innerHTML]="item.Detailed"></p>
        <div class="row" *ngFor="let annex of item.Annexes; let j = index;">
            <div class="col-lg-12 col-md-12 col-12">
                <h3 class="annexTitle">More about our endeavours</h3>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <img class="annexImg" [src]="share.home.apiURL + 'Uploads/Companies/Annex/' + annex.ImagePath" />
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <p style="font-weight: bold;">{{annex.Title}}</p>
                <div [innerHTML]="annex.Caption"></div>
            </div>
        </div>
    </ng-container>
</div>
<app-footer></app-footer>