import { Injectable, ViewContainerRef } from '@angular/core';
import { Attribute } from '@angular/compiler';
import { Gallery, Header, Home, Members, Search } from './interfaces'



@Injectable()
export class ShareService {
    public tableSrc: any;

    public header: Header = {
        images: []
    };

    public home: Home = {
        // apiURL: "http://localhost:4300/",
        apiURL: "https://panchalbrahminmahasabha.com/API/",
        language: "Hindi",
        ads: []
    }

    public search: Search = {
        items: [],
        searchedItem: "",
        searchData: [],
        searchGuard: false
    }

    public members: Members = {
        management: [],
        executive: [],
        common: []
    }

    public gallery: Gallery = {
        albums: [],
        albums2018: [],
        albums2016: []
    }
}