<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;सदस्य&nbsp;<i class="fas fa-angle-right"></i>&nbsp;कार्यकारिणी
    </div>
</div>
<div class="container" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">कार्यकारिणी</h1>
    <table class="table table-bordered table-condensed table-striped hindi">
        <thead>
            <tr>
                <th>क्र०</th>
                <th class="web">फ़ोटो</th>
                <th>नाम</th>
                <th>पद</th>
                <th>पता</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let member of members; let i = index;">
                <td>{{i+1}}</td>
                <td class="web">
                    <img class="profileImg" *ngIf="member.ProfilePic?.length > 0" [src]="share.home.apiURL + 'Uploads/Members/Executive-Members/' + member.ProfilePic" />
                    <img *ngIf="(!member.ProfilePic || member.ProfilePic?.length == 0) && (member?.Salutation == 'Mrs.' || member?.Salutation == 'Ms.')" src="assets/images/female-user.png" />
                    <img *ngIf="(!member.ProfilePic || member.ProfilePic?.length == 0) && member?.Salutation != 'Mrs.' && member?.Salutation != 'Ms.'" src="assets/images/male-user.png" />
                </td>
                <td>{{member.SalutationHindi}} {{member.NameHindi}}</td>
                <td>{{member.DesignationHindi}}</td>
                <td><span class="web" *ngIf="member.Address?.length > 0">{{member.Address}}, </span>{{member.City}}</td>
            </tr>
        </tbody>
    </table>
</div>
<div class="container" *ngIf="share.home.language == 'English'">
    <h1 class="pageTitle">Executive Committee</h1>
    <table class="table table-bordered table-condensed table-striped">
        <thead>
            <tr>
                <th>Sl</th>
                <th class="web">Photo</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Address</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let member of members; let i = index;">
                <td>{{i+1}}</td>
                <td class="web">
                    <img class="profileImg" [src]="share.home.apiURL + 'Uploads/Members/Executive-Members/' + member.ProfilePic" />
                    <img *ngIf="(!member.ProfilePic || member.ProfilePic?.length == 0) && (member?.Salutation == 'Mrs.' || member?.Salutation == 'Ms.')" src="assets/images/female-user.png" />
                    <img *ngIf="(!member.ProfilePic || member.ProfilePic?.length == 0) && member?.Salutation != 'Mrs.' && member?.Salutation != 'Ms.'" src="assets/images/male-user.png" />
                </td>
                <td>{{member.Salutation}} {{member.Name}}</td>
                <td>{{member.Designation}}</td>
                <td><span class="web" *ngIf="member.Address?.length > 0">{{member.Address}}, </span>{{member.City}}</td>
            </tr>
        </tbody>
    </table>
</div>
<app-footer></app-footer>