<app-header></app-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="bannerImg"></div>
<div class="breadCrumb">
    <div class="container hindi" *ngIf="share.home.language == 'Hindi'">
        होम&nbsp;<i class="fas fa-angle-right"></i>&nbsp;विश्वकर्मा&nbsp;<i class="fas fa-angle-right"></i>&nbsp;यन्त्रों के देव
    </div>
</div>
<div class="container hindi" *ngIf="share.home.language == 'Hindi'">
    <h1 class="pageTitle hindi">भगवान श्री विश्वकर्मा - यन्त्रों के देव</h1>
    <p>हम अपने प्राचीन ग्रन्थों, उपनिषद एवम् पुराण आदि का अवलोकन करें तो पायेगें कि आदि काल से ही विश्वकर्मा शिल्पी अपने विशिष्ट ज्ञान एवम् विज्ञान के कारण ही न मात्र मानवों अपितु देवगणों द्वारा भी पूजित और वन्दित रहे हैं। भगवान श्री विश्वकर्मा के आविष्कार
        एवम् निर्माण कार्यों के सन्दर्भ में इन्द्रपुरी, यमपुरी, वरुणपुरी, कुबेरपुरी, पाण्डवपुरी, सुदामापुरी, शिवमण्डलपुरी आदि का निर्माण इनके द्वारा किया गया है। पुष्पक विमान का निर्माण तथा सभी देवों के भवन और उनके दैनिक उपयोगी होनेवाले वस्तुएं को भी
        इनके द्वारा ही बनाया गया है। कर्ण का कुण्डल, विष्णु भगवान का सुदर्शन चक्र, शंकर भगवान का त्रिशूल और यमराज का कालदण्ड इत्यादि अस्त्र-शस्त्रों का निर्माण भगवान श्री विश्वकर्मा ने ही किया है।</p>
    <p>कलियुग का एक नाम कलयुग यानि कल का युग भी है। कल शब्द का एक अर्थ यन्त्र भी होता है अतः कलयुग का अर्थ हुआ यन्त्रों का युग। हम सुबह उठने से लेकर रात को सोने तक हजारों यन्त्रों के सहारे ही तो चलते हैं। फोन, बिजली, पानी की टंकी जैसे ना जाने कितने यन्त्र
        हम प्रतिदिन प्रयोग करते हैं; तो ऐसे में यन्त्रों के देव को हम भूल कैसे सकते हैं।</p>
    <p>भारत संस्कृति प्रधान देश है। हमारी संस्कृति में हर उस वस्तु को पूजनीय बताया गया है जिसका प्रयोग हम दैनिक जीवन में करते हैं फिर चाहे वह जल हो या अग्नि। और आवश्यक वस्तुओं के वस्तुतः देव भी हैं जैसे जल के लिए वरुणदेव, अग्नि के अग्निदेव, वायु के लिए हम
        पवनदेव को पूजते हैं आदि। इसी तरह जीवन में यन्त्रों का भी विशेष महत्व है।</p>
    <div class="inMatterImg">
        <img src="assets/images/HPBM-Shree-Vishwakarma-Yantro-ke-Dev.jpg" />
    </div>
    <p>भारतीय संस्कृति और पुराणों में भगवान श्री विश्वकर्मा को यन्त्रों का अधिष्ठाता और देव माना गया है। भगवान श्री विश्वकर्मा ने मानव को सुख-सुविधाएं प्रदान करने के लिए अनेक यन्त्रों व शक्ति सम्पन्न भौतिक साधनों का निर्माण किया। इनके द्वारा मानव समाज भौतिक
        चरमोत्कर्ष को प्राप्त कर रहा है। प्राचीन शास्त्रों में वैमानिक विद्या, नौविद्या, यन्त्र निर्माण विद्या आदि का भगवान श्री विश्वकर्मा ने उपदेश दिया है। प्राचीन समय में स्वर्ग लोक, लंका, द्वारिका, हस्तिनापुर जैसे स्थानों के भी रचयिता भगवान श्री विश्वकर्मा
        ही थे।</p>
    <p>विश्व के सबसे पहले तकनीकी ग्रन्थ विश्वकर्मीय ग्रन्थ ही माने गए हैं। "विश्वकर्मीयम" ग्रन्थ इनमें बहुत प्राचीन माना गया है, जिसमें न केवल वास्तुविद्या, बल्कि रथादि वाहन व रत्नों का भी विवरण है। "विश्वकर्माप्रकाश", जिसे वास्तुतन्त्र भी कहा गया है, विश्वकर्मा
        के मतों का जीवन्त ग्रन्थ है। इसमें मानव और देववास्तु विद्या को गणित के कई सूत्रों के साथ बताया गया है, ये सब प्रामाणिक और प्रासंगिक हैं। मेवाड़ में लिखे गए "अपराजितपृच्छा" में अपराजित के प्रश्नों पर विश्वकर्मा द्वारा दिए उत्तर लगभग साढ़े सात हज़ार
        श्लोकों में दिए गए हैं। संयोग से यह ग्रन्थ 239 सूत्रों तक ही मिल पाया है।</p>
</div>
<div class="container" *ngIf="share.home.language == 'English'">
    <h1 class="pageTitle">Shree Vishwakarma - Yantro Ke Dev</h1>
</div>
<app-footer></app-footer>